import React, { useState, useRef, useEffect } from "react";
import { ForgotIcon } from "helper/constant";
import Toaster from "../../../components/common/Toaster";
import { withRouter } from "react-router-dom";
import Login from "./Login";
import Button from "../../../components/button/Button";

const TwoStepAuthentication = props => {

  const loginContainer = {
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0,
    backgroundColor: "rgb(253, 243, 223)"
  };

  const toaster = useRef();
  const [otp, setOtp] = useState("");
  const [view, setView] = useState(true);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    setView(true);
    setloading(false);
  }, []);

  function submitHandler() {
    var validKey = props.location.state ? ((props.location.state.key / 7832) - 7832) : undefined;
    if (!otp) {
      toaster.current.error("Please enter security code");
    } else {
      if (!props.location.state) {
        toaster.current.error("Please try again");
        setTimeout(() => {
          props.history.push("/login");
          setView(false);
          setloading(true);
        }, 1000);
      } else {
        
        if (props.location.state && otp.toString() === validKey.toString()) {
          
          toaster.current.success("Security Code Correct");
          setloading(true);
          setTimeout(() => {
            props.history.push("/dashboard");
            setView(false);
            setloading(false);
          }, 1000);
        } else {
          setloading(false);
          toaster.current.error("Security Code Incorrect");
        }
      }
    }
  
  }

  return view ? (
    <div className="container-fluid" style={loginContainer}>
      <div className="form-container">
        <div className="login-icon">
          <img src={ForgotIcon} alt="icon" height="100px" />
        </div>
        <div className="login-title">Enter Security Code</div>
        <div className="text-center form-info-text plr-24 mt-16">
        Security Code has been provided to your email id
        </div>
        <form className="pa-24">
          <div className="form-group">
            <input
              type="text"
              required
              onChange={e => {
                setOtp(e.target.value);
              }}
              className="form-control react-form-input"
              id="code"
              aria-describedby="emailHelp"
              placeholder="enter security code"
            />
          </div>
          <Button
            type="button"
            loading={loading}
            className="btn form-button"
            dataStyle="expand-right"
            style={{ position: "relative" }}
            onClick={submitHandler}
          >
            Verify Security Code
          </Button>
        </form>
      </div>
      <Toaster ref={toaster} />
    </div>
  ) : (
    <Login email={props.email} otp={otp} />
  );
};

export default withRouter(TwoStepAuthentication);
