import React, { useEffect, useState, useRef } from "react";
import PageTitle from "components/common/PageTitle";
import PageWrapper from "../StaticPage/page.style";
import SubAdminForm from "../../components/Admins/SubAdminForm";
import { connect } from "react-redux";
import { compose } from "redux";
import Toaster from "components/common/Toaster";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import Api from "../../helper/Api";

const SubAdminEdit = props => {
  
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const toaster = useRef();
  const hasParam = props.match.params.id;
  const [id, setId] = useState(null);

  useEffect(() => {
    // if (hasParam) setId(Number(props.match.params.id));
    Api("POST", `cms-api/admin/retrive/${hasParam}`,)
    .then(res => {
      setId(res.data.data.admin_id);
      // setLoader(false)
    }).catch(err=>{
      // setLoader(false)
    });
    // eslint-disable-next-line
  }, []);

  // const addPageHandler = data => {
  //   // ContentServices.add(data)
  //   Api("POST", `cms-api/admin/add`,data)
  //     .then(response => {
  //       if (response.data.status === 1) {
  //         setLoading(false);
  //         toaster.current.success(response.data.message);
  //         setTimeout(() => {
  //           history.push("/admin");
  //         }, 1000);
  //       } else {
  //         setLoading(false);
  //         if (toaster.current) toaster.current.error(response);
  //       }
  //     })
  //     .catch(error => {
  //       setLoading(false);
  //       if (toaster.current) toaster.current.error(error);
  //     });
  // };

  const editPageHandler = data => {
    const params = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      adminType : data.adminType,
      password : data.password
    };
    // ContentServices.edit(id, data)
    Api("PUT", `cms-api/admin/edit/${id}`,params)
      .then(response => {    
        if (response.data.status === 1) {
          toaster.current.success(response.data.message);
          setTimeout(() => {
            history.push("/sub-admin");
          }, 1000);
        } else {
          setLoading(false);
          if (toaster.current) toaster.current.error(response);
        }
      })
      .catch(error => {
        setLoading(false);
        if (toaster.current) toaster.current.error(error);
      });
  };

  return (
    <PageWrapper>
    <div className="pos-relative">
        <PageTitle
          title="sidebar.subadmin"
          className="plr-15"
          breadCrumb={[
            {
              name: "sidebar.subadmin" 
            },
            {
              name: hasParam ? "action.edit" : "action.add"
            }
          ]}
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/sub-admin")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>
      {/* <PageTitle title="sidebar.contents" className="plr-15" /> */}
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header"></div>
          <div className="roe-card-body">
            <SubAdminForm
              // onAdd={addPageHandler}
              action={hasParam ? "edit" : "add"}
              loading={loading}
              id={id}
              onEdit={editPageHandler}
            />
          </div>
          <Toaster ref={toaster} />
        </div>
      </div>
    </PageWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default compose(withRouter, connect(mapStateToProps, null))(SubAdminEdit);
