import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    module_name: Yup.string().required("This field is required"),
  }),
  mapPropsToValues: props => ({
    module_name: props.editedData ? props && props.editedData && props.editedData.module_name : "",
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
