import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import Button from "components/button/Button";
import formikEnhancer from "./validator";
import Api from "../../helper/Api";

const RoleForm = (props) => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    values,
    submitCount,
    onAdd,
    setFieldValue,
    loading,
    onEdit,
    action,
  } = props;
  let { id } = useParams();

  useEffect(() => {
    if (id && action === "edit") {
      // ContentServices.view(id)
      Api("POST", `cms-api/admin-role/role-retrive/${id}`)
      .then((result) => {
        setFieldValue("module_name", result.data.data.module_name);
      });
    } else if (id === null && action === "add") {
      setFieldValue("module_name", "");
    }
    // eslint-disable-next-line
  }, [id]);

  const Error = (props) => {
    const field1 = props.field;
    if (
      (errors[field1] && touched[field1]) ||
      submitCount > 0 ||
      field1 === ""
    ) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };   

  const handleSubmit = (e) => {
    let { isValid, handleSubmit, values } = props;
    e.preventDefault();
    if (action === "add" && isValid) {
      onAdd(values, action);
    }
    if (action === "edit" && isValid) {
      onEdit(values, action);
    }
    if (!isValid) {
      handleSubmit();
    }
  };
  return (
    <>
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <div className="form-group">
          <label className="fs-16 medium-text">
            Role Name <span className="error-msg">*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="module_name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.module_name}
            placeholder="role name"
          />
          <Error field="module_name" />
        </div>
        <div>
          <Button
            type="submit"
            loading={loading}
            // onclick={() => handleSubmit()}
            className="c-btn c-info form-button fs-16 demi-bold-text mr-15"
            style={{ maxWidth: "125px" }}
            dataStyle="expand-right"
            // disabled= {false} 
            disabled= {props.dirty === false }
          >
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

export default formikEnhancer(RoleForm);
