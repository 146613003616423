import React, { useRef, useState, useEffect } from "react";
import { /*iconDemo,*/ kbagIcon } from "helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import AuthActions from "redux/auth/actions";
import enhancer from "./enhancer/LoginFormEnhancer";
import Toaster from "../../../components/common/Toaster";
import Button from "../../../components/button/Button";
import Api from "../../../helper/Api";
// import AdminServices from "../../../api/AdminServices";
import PasswordField from 'material-ui-password-field';

const { login } = AuthActions;

const Login = (props) => {
  const [loading, setloading] = useState(false);

  const toaster = useRef();

  useEffect(() => {
    // if (props.isLogin) {
    //   props.history.push("/dashboard");
    // }
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    let { values, handleSubmit } = props;

    if (values.email !== "" && values.password !== "") {
      const params = {
        email: values.email,
        password: values.password,
      };
      // props.history.push("/static-page");
      setloading(true);
      Api("POST","cms-api/admin/login",params)
      // AdminServices.login(params)
        // Api("POST","login",params)
        .then((res) => {
          if (res.data.status === 1) {
            // if (toaster.current) toaster.current.success(res.data.message);
            props.login({
              token: res.data.data.token,
              firstName: res.data.data.firstName,
              lastName: res.data.data.lastName,
              email: res.data.data.email,
              admin_id: res.data.data.admin_id,
              photo: res.data.data.photo,
              adminType: res.data.data.adminType,
              admin_assignrole: res.data.data.admin_assignrole,
            });
            setloading(false);
            setTimeout(() => {
              // props.history.push("/dashboard");
              props.history.push("/twoStepAuthentication", { key: res.data.data.passKey });
            }, 500);
          } else {
            setloading(false);
            if (toaster.current) toaster.current.error(res.data.message);
          }
        })
        .catch((err) => {
          setloading(false);
          if (toaster.current) toaster.current.error(err.response);
        });

      //   const data = {
      //     token: "DEMOJWTTOKEN"
      //   };
      // using this method you can store token in redux

      //   props.history.push("/dashboard");
    }
    handleSubmit();
  };

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
  } = props;

  const loginContainer = {
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0,
    backgroundColor: "#FDF3DF",
  };

  const Error = (props) => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="container-fluid" style={loginContainer}>
      <div className="form-container">
        <div className="login-icon">
          <img
            // src={iconDemo} // remove kyulebag icon  
            src={kbagIcon}  
            alt="icon"
            height="100px"
            // className="login-icon1" // remove kyulebag icon 
            className="login-icon"
          />
        </div>
        <div className="login-title">Sign in to your account</div>
        <form className="pa-24" onSubmit={handleLogin}>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              className="form-control react-form-input"
              id="email"
              onChange={handleChange}
              value={values.email}
              onBlur={handleBlur}
              placeholder="Email"
            />
            <Error field="email" />
          </div>

          <div className="form-group">
            <label>Password</label>
            {/* <input
              type="password"
              className="form-control react-form-input"
              id="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Password"
            /> */}

            <PasswordField
              className="form-control react-form-input "
              // sx={{border-bottom: "none"}}
              id="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Password"
            />
            <Error field="password" />
          </div>

          <Button
            type="submit"
            loading={loading}
            className="btn form-button"
            dataStyle="expand-right"
            style={{ position: "relative" }}
          >
            Login
          </Button>
          <div
            className="text-center link-label"
            onClick={() => props.history.push("/forgotPassword")}
          >
            Forgot Password ?
          </div>
        </form>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLogin: state.auth.isLogin,
  };
};

export default compose(
  withRouter,
  enhancer,
  connect(mapStateToProps, { login })
)(Login);
