import React, { useEffect, useState, useRef } from "react";
import PageTitle from "components/common/PageTitle";
import PageWrapper from "../StaticPage/page.style";
import RoleForm from "../../components/Role/RoleForm";
import { connect } from "react-redux";
import { compose } from "redux";
import Toaster from "components/common/Toaster";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import Api from "../../helper/Api";

const RoleEdit = props => {
  
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const toaster = useRef();
  const hasParam = props.match.params.id;
  const [id, setId] = useState(null);

  useEffect(() => {
    Api("POST", `cms-api/admin-role/role-retrive/${hasParam}`,)
    .then(res => {
      setId(res.data.data.role_id);
      // setLoader(false)
    }).catch(err=>{
      // setLoader(false)
    });
    // eslint-disable-next-line
  }, []);

  const addPageHandler = data => {
    Api("POST", `cms-api/admin-role/add-role`,data)
      .then(response => {
        if (response.status === 200) {
          setLoading(false);
          toaster.current.success(response.data.message);
          setTimeout(() => {
            history.push("/roles");
          }, 1000);
        } else {
          setLoading(false);
          if (toaster.current) toaster.current.error(response);
          setTimeout(() => {
            history.push("/roles");
          }, 3000);
        }
      })
      .catch(error => {
        setLoading(false);
        if (toaster.current) toaster.current.error(error);
        setTimeout(() => {
          history.push("/roles");
        }, 3000);
      });
  };

  const editPageHandler = data => {
    const params = {
      module_name: data.module_name,
    };
    Api("PUT", `cms-api/admin-role/edit-role/${id}`,params)
      .then(response => {        
        if (response.status === 200) {
          toaster.current.success(response.data.message);
          setTimeout(() => {
            history.push("/roles");
          }, 1000);
        } else {
          setLoading(false);
          if (toaster.current) toaster.current.error(response);
        }
      })
      .catch(error => {
        setLoading(false);
        if (toaster.current) toaster.current.error(error);
      });
  };

  return (
    <PageWrapper>
    <div className="pos-relative">
        <PageTitle
          title="sidebar.role"
          className="plr-15"
          breadCrumb={[
            {
              name: "sidebar.role"
            },
            {
              name: hasParam ? "action.edit" : "action.add"
            }
          ]}
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/roles")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>
      {/* <PageTitle title="sidebar.contents" className="plr-15" /> */}
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header"></div>
          <div className="roe-card-body">
            <RoleForm
              onAdd={addPageHandler}
              action={hasParam ? "edit" : "add"}
              loading={loading}
              id={id}
              onEdit={editPageHandler}
            />
          </div>
          <Toaster ref={toaster} />
        </div>
      </div>
    </PageWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default compose(withRouter, connect(mapStateToProps, null))(RoleEdit);
