/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
// import ContentServices from "../../api/ContentServices";
import Api from "../../helper/Api";
import ReactHtmlParser from "html-react-parser";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import Moment from "react-moment";
import { TABLE } from "../../helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import AuthActions from "redux/auth/actions";
import moment from "moment";
import { store } from "redux/store";

// import ResendNotification from "./ResendModal";

const { logout } = AuthActions;

const CommentTable = (props) => {
  const toaster = useRef();
  const [tblData, settblData] = useState();
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("createdAt");
  const [sortBy, setSortBy] = useState("DESC");

  // const [viewModal, setviewModal] = useState(false);
  // const [viewData, setViewData] = useState(null);
  const [showPagination, setShowPagination] = useState(false);
  //   const [resendNotification, setResendNotification] = useState({});
  //   const [resendOpen, setResendOpen] = useState(false);
  // var assignRole = props.admin_assignrole;

  useEffect(() => {
    setLoader(true);
    getPagesList(
      field,
      sortBy,
      searchText,
      activePage + 1,
      props.location.pathname === "/comments"
    );
    setPages(10);
    // eslint-disable-next-line
  }, [searchText]);

  const exportCommentsAsCSV = () => {
    const csvData = [['Comment', 'Who is created', 'Email', 'Created Date']]

    if(!!tblData){
      tblData.forEach((each) => {
        const createdDateTime = moment(each.createdAt).format("YYYY-MM-DD HH:mm:ss")
        let tmp = [`"${each.content}"`, each.first_name, each.email, createdDateTime]
        csvData.push(tmp)
      })
    }else{
      return
    }

    function makeCSV(allRows) {
      return allRows.map((r) => r.join(',')).join('\n')
    }

    const comments = document.getElementById('comments')
    const blobObj = new Blob([makeCSV(csvData)])
    comments.href = URL.createObjectURL(blobObj)
  }

  const getPagesList = (field, sortBy, searchText, activePage) => {
    const params = {
      sort_by: field,
      order: sortBy,
      search: searchText,
      page: activePage,
    };
    setLoader(true);
    // ContentServices.list(params)
    Api("POST", "cms-api/comments/list", params)
      .then((result) => {
        if (result.data.status) {
          settblData(result.data.data.rows);
          if (result.data.data.count > 10) {
            setShowPagination(true);
          } else {
            setShowPagination(false);
          }
          setPages(Math.ceil(result.data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          settblData(result.data.data.rows);
          setShowPagination(false);
          setLoader(false);
          if (toaster.current) toaster.current.error(result.data.message);
        }
      })
      .catch((err) => {
        if (err.response.status === 500) {
          if (toaster.current) toaster.current.error(err.response.data.message);
          setTimeout(() => {
            props.history.push("/dashboard");
          }, 1000);
        }
        if (err.response.status === 401) {
          if (toaster.current) toaster.current.success("You have been logged out since you were idle for more than 30 mins.");
          // alert("You have been logged out since you were idle for more than 30 mins.");
          setTimeout(() => {
            store.dispatch(logout());
            props.history.push("/login");
          }, 2000);
        }
      });
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    getPagesList(field, sortBy, e.target.value, activePage + 1);
    setLoader(true);
    setActivePage(0);
  };

  // const resendHandler = (props) => {
  //   // setLoader(true)
  //   Api(
  //     "POST",
  //     `cms-api/email-notification/retrive/${props.email_notification_id}`
  //   )
  //     .then((res) => {
  //       toaster.current.success("Successfully Resend Notification");
  //       setTimeout(() => {
  //         // document.location.reload(); // soft ref page
  //         // setLoader(false);
  //       }, 1000);
  //     })
  //     .catch((err) => {
  //       // setLoader(false);
  //     });
  // };
  // const viewHandler = data => {
  //   setviewModal(true);
  //   // ContentServices.view(data.static_contents_id)
  //   Api("POST", `cms-api/admin/retrive/${data.admin_id}`)
  //   .then(result => {
  //     setViewData(result.data.data);
  //   });
  // };

  // const formAction = (action, data) => {
  //   if (action === "add") {
  //     props.history.push(`/comments/add`);
  //   }
  //   // else if (action === "edit") {
  //   //   props.history.push(`/notification/edit/${data.notification_id}`);
  //   // }
  // };

  const columns = [
     {
      Header: "Comment",
      className: "text-left pl-2",
      filterable: false,
      accessor: "content",
      headerClassName: "react-table-header-class text-left pl-2",
      width: 500,
      style: { whiteSpace: "unset" },
      Cell: (props) => <div>{ReactHtmlParser(props.original.content)}</div>,
    },
    {
      Header: "Who is created",
      className: "text-left pl-2",
      filterable: false,
      sortable: false,
      accessor: "first_name + last_name",
      headerClassName: "react-table-header-class text-left pl-2",
      width: 200,
      style: { whiteSpace: "unset" },
      Cell: (props) => <div>{ReactHtmlParser(props.original.first_name + ` ${props.original.last_name ? props.original.last_name : ""}`)}</div>,
    },
    {
      Header: "Email",
      className: "text-left pl-2",
      filterable: false,
      sortable: false,
      accessor: "email",
      headerClassName: "react-table-header-class text-left pl-2",
      width: 300,
      style: { whiteSpace: "unset" },
      Cell: (props) => <div>{ReactHtmlParser(props.original.email)}</div>,
    },
    {
      Header: "Date",
      className: "text-left d-flex align-items-center",
      filterable: false,
      accessor: "createdAt",
      headerClassName: "react-table-header-class text-left pl-2",
      width: 250,
      Cell: (props) => (
        <div>
          <Moment format="DD-MMM-YYYY h:mm:ss a ">
            {props.original.createdAt}
          </Moment>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text" />{" "}
            <div className="mr-10">
              <input
                value={searchText}
                onChange={handleSearch}
                type="text"
                placeholder="Search Comment..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
                // style={{ border: "2px solid #20b2aa" }}
              />
            </div>
            <div>
              <a id='comments' download='comments.csv'>
                <Button
                  className="c-btn ma-5"
                  onClick={() => exportCommentsAsCSV()}
                  style={{
                    backgroundColor: "#472b1c",
                    color: "#fff",
                  }}
                >
                  {/* <i className="fas fa-plus mr-2" /> */}
                  <i className="fas fa-file-export mr-2"></i>
                  Export
                  {/* {type.charAt(0).toUpperCase() + type.slice(1)} */}
                </Button>
              </a>
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none",
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              filterable
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              showPagination={showPagination}
              LoadingComponent={Loader}
              // onFetchData={onChangePageGetData}
              onPageChange={(pageIndex) => {
                setActivePage(pageIndex);
                getPagesList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={(sortProperties) => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getPagesList(sortProperties[0].id, sort, searchText, 1);
              }}
            />
          </div>
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    admin_assignrole: state.auth.admin_assignrole,
    ...state.auth,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, { logout })
)(CommentTable);
