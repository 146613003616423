import React, { useRef, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import enhancer from "./validator";
import Toaster from "../common/Toaster";
import { withRouter } from "react-router-dom";
import AuthActions from "../../redux/auth/actions";
// import { ImageResolution } from "../../helper/constant";
import SmallLoader from "../common/SmallLoader";
import { adminProfile } from "helper/constant";
import Api from "helper/Api";
// import AdminServices from "../../api/AdminServices";

const { admin } = AuthActions;

const EditProfileForm = (props) => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    // onSubmit,
    setFieldValue,
    // setFieldTouched,
    values,
    // action,
  } = props;

  const [uploadImage, setUploadImage] = useState(values.photo);
  const [imageObj, setImageObj] = useState(null);
  const [loading, setloading] = useState(false);
  // const [disabled, setDisabled] = useState(false);

  const toaster = useRef();

  const Error = (props) => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const handleImage = (e, setValue) => {
    if (e.target.files[0]) {
      let fileObj = e.target.files[0];
      setImageObj(fileObj);
      if (fileObj) {
        // const img = new Image();

        // img.src = window.URL.createObjectURL(fileObj);

        // img.onload = function() {
        //   const width = img.naturalWidth,
        //     height = img.naturalHeight;

        //   window.URL.revokeObjectURL(img.src);
        //   // setValue("width", width);
        //   // setValue("height", height);
        //   setFieldTouched("image", true, true);
        //   if (
        //     width >= ImageResolution.WIDTH &&
        //     height >= ImageResolution.HEIGHT
          // ) 
          // {
            setValue("photo",fileObj);
            setUploadImage(URL.createObjectURL(fileObj));
          // }
        // };
      } else {
        //No file was input or browser doesn't support client side reading
        // form.submit();
      }
    } 
    else {
      setUploadImage(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let { values, isValid, handleSubmit } = props;

    if (isValid) {
      const formData = new FormData();

      formData.append("admin_id", props.admin_id);
      formData.append("first_name", values.firstname);
      formData.append("last_name", values.lastname);

      if (imageObj) {
        formData.append("photo", imageObj);
      }

      setloading(true);
      // setDisabled(true);
      Api("PUT", "cms-api/admin/edit-profile", formData)
      // AdminServices.editprofile(formData)
        .then((res) => {
          if (res.data.status) {
            if (toaster.current) toaster.current.success(res.data.message);
            props.admin({
              firstName: res.data.data.firstName,
              lastName: res.data.data.lastName,
              photo: res.data.data.photo,
              email: res.data.data.email,
              admin_id: res.data.data.admin_id,
              adminType: res.data.data.adminType,
              admin_assignrole: res.data.data.admin_assignrole,
            });
            localStorage.setItem("photo", res.data.data.photo);
            setTimeout(() => {
              props.history.push("/dashboard");
              setloading(false);
              // setDisabled(false);
            }, 1000);
          } else {
            setloading(false);
            // setDisabled(false);
            if (toaster.current) toaster.current.error(res.data.message);
          }
        })
        .catch((err) => {
          setloading(false);
          // setDisabled(false);
          if (toaster.current) toaster.current.error(err.response);
        });
    }
    handleSubmit();
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>

      <div>
          <div className="form-group">
            <label className="fs-16 medium-text">Profile Image</label>
            <br />
            <div className="file-upload">
              <label className="c-btn c-primary form-button fs-16 demi-bold-text mt-15">
                Upload image
                <input
                  id="photo"
                  className="file-upload__input"
                  name="file-upload"
                  type="file"
                  accept="image/jpg,image/png,image/jpeg"
                  onChange={(e) => handleImage(e, setFieldValue)}
                  onBlur={handleBlur}
                />
              </label>
            </div>
            <br />
            <img
              src={uploadImage ? uploadImage : adminProfile}
              alt=""
              className="mtb-15"
              style={{
                width: "200px",
                borderRadius: "4%",
                height: "150px",
                // background: "#404040",
              }}
            />
            <br />
            <Error field="photo" />
          </div>
        </div>
        
        <div className="form-group">
          <label className="fs-16 medium-text">First Name</label>
          <input
            type="text"
            className="form-control react-form-input"
            id="firstname"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstname}
            placeholder="First Name"
          />
          <Error field="firstname" />
        </div>

        <div className="form-group">
          <label className="fs-16 medium-text">Last Name</label>
          <input
            type="text"
            className="form-control react-form-input"
            id="lastname"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastname}
            placeholder="Last Name"
          />
          <Error field="lastname" />
        </div>

        <div className="form-group">
          <label className="fs-16 medium-text">Email</label>
          <input
            type="email"
            className="form-control react-form-input"
            id="email"
            disabled
            onChange={handleChange}
            onBlur={handleBlur}
            value={props.email}
            placeholder="Email"
          />
          <Error field="email" />
        </div>

        {/* <div>
          <div className="form-group">
            <label className="fs-16 medium-text">Profile Image</label>
            <br />
            <div className="file-upload">
              <label className="c-btn c-primary form-button fs-16 demi-bold-text mt-15">
                Upload image
                <input
                  id="photo"
                  className="file-upload__input"
                  name="file-upload"
                  type="file"
                  accept="image/jpg,image/png,image/jpeg"
                  onChange={(e) => handleImage(e, setFieldValue)}
                  onBlur={handleBlur}
                />
              </label>
            </div>
            <br />
            <img
              src={uploadImage ? uploadImage : values.photo}
              alt=""
              className="mtb-15"
              style={{
                width: "200px",
                borderRadius: "4%",
                height: "150px",
                background: "#404040",
              }}
            />
            <br />
            <Error field="photo" />
          </div>
        </div> */}

        <div>
          {!props.loading && (
            <button
              type="submit"
              className="c-btn c-info form-button fs-16 demi-bold-text"
              style={{ maxWidth: "125px" }}
              // disabled={disabled}
              // disabled
              disabled= {props.dirty === false }
            >
              Submit
            </button>
          )}
          {loading && <SmallLoader />}
        </div>
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    firstName: state.auth.firstName,
    lastName: state.auth.lastName,
    email: state.auth.email,
    admin_id: state.auth.admin_id,
    photo: state.auth.photo,
    adminType: state.auth.adminType,
    // profile_image: state.auth.profile_image,
    ...state.themeChanger,
  };
};
export default compose(
  withRouter,
  connect(mapStateToProps, { admin }),
  enhancer
)(EditProfileForm);
