import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
// import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
// import ContentServices from "../../api/ContentServices";
import Api from "../../helper/Api";
// import ReactHtmlParser from "html-react-parser";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import { TABLE } from "../../helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
// import AdminServices from "api/AdminServices";
import AuthActions from "redux/auth/actions";
import CommonModel from "../common/CommonModel1";
import Tooltip from "@mui/material/Tooltip";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { store } from "redux/store";
// import Button from "@mui/material/Button";

const { logout } = AuthActions;

const PageTable = (props) => {
  const toaster = useRef();
  const [tblData, settblData] = useState();
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("createdAt");
  const [sortBy, setSortBy] = useState("DESC");
  const [viewModal, setviewModal] = useState(false);
  // const [viewData, setViewData] = useState(null);
  const [showPagination, setShowPagination] = useState(false);
  var assignRole = props.admin_assignrole;

  useEffect(() => {
    setLoader(true);
    getPagesList(
      field,
      sortBy,
      searchText,
      activePage + 1,
      props.location.pathname === "/pages"
    );
    setPages(10);
    // eslint-disable-next-line
  }, [searchText]);

  const [staticPage, setStaticPageData] = useState({
    content: <span></span>,
    title: ""
  });
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  // const userSignout = () => {
  //   AdminServices.logout();
  //   props.logout();
  // };

  const getPagesList = (field, sortBy, searchText, activePage) => {
    const params = {
      sort_by: field,
      order: sortBy,
      search: searchText,
      page: activePage,
    };
    setLoader(true);
    // ContentServices.list(params)
    Api("POST", "cms-api/static-content/list", params).then((result) => {
      if (result.data.status) {
        settblData(result.data.data.rows);
        if (result.data.data.count > 10) {
          setShowPagination(true);
        } else {
          setShowPagination(false);
        }
        setPages(Math.ceil(result.data.data.count / TABLE.LIMIT));
        setLoader(false);
      } else {
        settblData(result.data.data.rows);
        setShowPagination(false);
        setLoader(false);
        if (toaster.current) toaster.current.error(result.data.message);
      }
    })
      .catch((err) => {
        if (err.response.status === 500) {
          if (toaster.current) toaster.current.error(err.response.data.message);
          setTimeout(() => {
            props.history.push("/dashboard");
          }, 1000);
        }
        if (err.response.status === 401) {
          if (toaster.current) toaster.current.success("You have been logged out since you were idle for more than 30 mins.");
          // alert("You have been logged out since you were idle for more than 30 mins.");
          setTimeout(() => {
            store.dispatch(logout());
            props.history.push("/login");
          }, 2000);
        }
      });
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    getPagesList(field, sortBy, e.target.value, activePage + 1);
    setLoader(true);
    setActivePage(0);
  };

  // const viewHandler = (data) => {
  //   setviewModal(true);
  //   // ContentServices.view(data.static_contents_id)
  //   Api(
  //     "POST",
  //     `cms-api/static-content/retrive/${data.static_contents_id}`
  //   ).then((result) => {
  //     setViewData(result.data.data);
  //   });
  // };

  const viewHandler = data => {
    Api(
      "POST",
      `cms-api/static-content/retrive/${data.static_contents_id}`
    ).then((result) => {
      let pageModalData = {
        content: result.data.data.content,
        title: result.data.data.title
      };
      setStaticPageData(pageModalData);
      setModal(true);
    });
  };

  const formAction = (action, data) => {
    if (action === "add") {
      props.history.push(`/pages/add`);
    } else if (action === "edit") {
      props.history.push(`/pages/edit/${data.static_contents_id}`);
    }
  };

  const columns = [
    {
      Header: "Title",
      className: "text-left ml-2 d-flex align-items-center",
      filterable: false,
      accessor: "title",
      headerClassName: "react-table-header-class text-left ml-2",
      // width:
    },
    // {
    //   Header: "Content",
    //   className: "text-left ml-2",
    //   filterable: false,
    //   accessor: "content",
    //   // accessor: {ReactHtmlParser("content")},
    //   headerClassName: "react-table-header-class text-left ml-2",
    //   width: 850,
    //   style: { whiteSpace: "unset" },
    //   Cell: (viewData) => (
    //     <div>
    //       {/* <p>{ReactHtmlParser(viewData.original.content)}</p> */}
    //       {ReactHtmlParser(viewData.original.content)}
    //     </div>
    //   ),
    // },
    {
      Header: "Action",
      accessor: "action",
      className: "d-flex align-items-center justify-content-center",
      headerClassName: "react-table-header-class text-center",
      filterable: false,
      // width: 380,
      Cell: (props) => (
        <div className="react-action-class d-flex">
          <Tooltip title="View" placement="top" arrow>
            <div className="fs-14 medium-text text-primary cursor-pointer mr-3">
            <i
                  className="fas fa-eye fa-lg"
                  onClick={() => viewHandler(props.original)}
                ></i>
              </div>
          </Tooltip> 
          {assignRole[1].edit === true ?
          <Tooltip title="Edit" placement="top" arrow>
            <div className="fs-14 medium-text text-success ml-3 mr-3 cursor-pointer">
              <i
                className="far fa-edit fa-lg"
                onClick={() => {
                  formAction("edit", props.original);
                }}
              />
            </div>
          </Tooltip> : "-"
          }
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Modal centered size="lg" isOpen={modal} toggle={toggle} >
        <ModalHeader toggle={toggle}>{staticPage.title}</ModalHeader>
        <ModalBody>
          <p dangerouslySetInnerHTML={{ __html: staticPage.content }}></p>
        </ModalBody>
      </Modal>
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text" />{" "}
            <div className="mr-10">
              <input
                value={searchText}
                onChange={handleSearch}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
                // style={{ border: "2px solid #20b2aa" }}
              />
            </div>
            {/* {assignRole[1].add === true ? 
            <div>
              <Button
                className="c-btn ma-5"
                onClick={() => formAction("add", null)}
                style={{
                  backgroundColor: "#472b1c",
                  color: "#fff",
                }}
              >
                <i className="fas fa-plus mr-2" />
                Add
              </Button>
            </div> : false
            } */}
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none",
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              filterable
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              showPagination={showPagination}
              LoadingComponent={Loader}
              // onFetchData={onChangePageGetData}
              onPageChange={(pageIndex) => {
                setActivePage(pageIndex);
                getPagesList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={(sortProperties) => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getPagesList(sortProperties[0].id, sort, searchText, 1);
              }}
            />
          </div>
        </div>
      </div>
      {/* <CommonModel
              modal={modal}
              size="small"
              style={{ maxWidth: "400px", width: "70%" }}
              children={
                <View
                  userPageData={userPageData}
                  toggle={() => {
                    setModal(false);
                  }}
                />
              }
            /> */}
      <CommonModel
        modal={viewModal}
        toggle={() => {
          setviewModal(!viewModal);
        }}
        viewModalCheck={true}
        headerData={"Page Details"}
      >
      </CommonModel>
      <Toaster ref={toaster} />
    </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    admin_assignrole: state.auth.admin_assignrole,
    ...state.auth,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, { logout })
)(PageTable);
