import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
// import Button1 from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import Api from "../../helper/Api";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import { TABLE } from "../../helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import AuthActions from "redux/auth/actions";
import CommonModel from "../common/CommonModel1";
import Delete from "./DeletModal";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import { store } from "redux/store";

const { logout } = AuthActions;

const SubscriptionTable = (props) => {
  const toaster = useRef();
  const [tblData, settblData] = useState();
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("createdAt");
  const [sortBy, setSortBy] = useState("DESC");
  const [viewModal, setviewModal] = useState(false);
  const [viewData, setViewData] = useState(null);
  const [showPagination, setShowPagination] = useState(false);
  const [userToDEL, setUserToDEL] = useState({});
  const [deleteOpen, setDeleteOpen] = useState(false);

  useEffect(() => {
    setLoader(true);
    getPagesList(
      field,
      sortBy,
      searchText,
      activePage + 1,
      props.location.pathname === "/subscription"
    );
    setPages(10);
    // eslint-disable-next-line
  }, [searchText]);

  // const userSignout = () => {
  //   AdminServices.logout();
  //   props.logout();
  // };

  const getPagesList = (field, sortBy, searchText, activePage) => {
    const params = {
      sort_by: field,
      order: sortBy,
      search: searchText,
      page: activePage,
    };
    setLoader(true);
    // ContentServices.list(params)
    Api("POST", "cms-api/subscription/list", params)
      .then((result) => {
        if (result.data.status) {
          settblData(result.data.data.rows);
          if (result.data.data.count > 10) {
            setShowPagination(true);
          } else {
            setShowPagination(false);
          }
          setPages(Math.ceil(result.data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          // settblData(result.data.data.rows);
          // setShowPagination(false);
          setLoader(false);
          if (toaster.current) toaster.current.error(result.data.message);
        }
      })
      .catch((err) => {
        if (err.response.status === 500) {
          if (toaster.current) toaster.current.error(err.response.data.message);
          setTimeout(() => {
            props.history.push("/dashboard");
          }, 1000);
        }
        if (err.response.status === 401) {
          if (toaster.current) toaster.current.success("You have been logged out since you were idle for more than 30 mins.");
          // alert("You have been logged out since you were idle for more than 30 mins.");
          setTimeout(() => {
            store.dispatch(logout());
            props.history.push("/login");
          }, 2000);
        }
      });
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    getPagesList(field, sortBy, e.target.value, activePage + 1);
    setLoader(true);
    setActivePage(0);
  };

  const viewHandler = (data) => {
    setviewModal(true);
    // ContentServices.view(data.static_contents_id)
    Api("POST", `cms-api/subscription/retrive/${data.subscription_id}`).then((result) => {
      setViewData(result.data.data);
    });
  };

  const formAction = (action, data) => {
    if (action === "add") {
      props.history.push(`/subscription/add`);
    } else if (action === "edit") {
      props.history.push(`/subscription/edit/${data.subscription_id}`);
    } else if (action === "delete") {
      Api("PUT", `cms-api/subscription/delete/${data.subscription_id}`)
        .then((res) => {
          document.location.reload(); // soft ref page
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const columns = [
    {
      Header: "Storage",
      className: "text-left pl-2",
      filterable: false,
      accessor: "storage",
      headerClassName: "react-table-header-class text-left pl-2",
    //   width: 150,
    },
    {
      Header: "Amount",
      className: "text-left pl-2",
      filterable: false,
      accessor: "price",
      headerClassName: "react-table-header-class text-left pl-2",
      width: 150,
    },
    {
      Header: "Duration",
      className: "text-left pl-2",
      filterable: false,
      accessor: "duration",
      headerClassName: "react-table-header-class text-left pl-2",
    //   width: 350,
    },
    {
      Header: "Status",
      className: "text-left pl-2",
      filterable: false,
      accessor: "status",
      headerClassName: "react-table-header-class text-left pl-2",
    //   width: 200,
    },
    {
      Header: "Action",
      accessor: "action",
      className: "text-center",
      headerClassName: "react-table-header-class",
      filterable: false,
      // width: 280,
      Cell: (props) => {
        return (
          <div className="react-action-class d-flex justify-content-center">
            <Tooltip title="View" placement="top" arrow>
              <div className="fs-14 medium-text text-primary cursor-pointer">
                <i
                  className="fas fa-eye fa-lg"
                  onClick={() => viewHandler(props.original)}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Edit" placement="top" arrow>
              <div className="fs-14 medium-text text-success ml-3 mr-3 cursor-pointer">
                <i
                  className="far fa-edit fa-lg"
                  onClick={() => {
                    formAction("edit", props.original);
                  }}
                ></i>
              </div>
            </Tooltip>

            <Tooltip title="Delete" placement="top" arrow>
              <div className="fs-14 medium-text text-danger cursor-pointer">
                <i
                  className="fas fa-trash-alt fa-lg"
                  onClick={() => {
                    // formAction("delete", props.original);
                    setUserToDEL(props.original);
                    setDeleteOpen(true);
                  }}
                ></i>
              </div>
            </Tooltip>

          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text" />{" "}
            <div className="mr-10">
              <input
                value={searchText}
                onChange={handleSearch}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
                // style={{ border: "2px solid #20b2aa" }}
              />
            </div>
            {/* <div>
              <Button1
                className="c-btn ma-5"
                onClick={() => formAction("add", null)}
                style={{
                  backgroundColor: "#472b1c",
                  color: "#fff",
                }}
              >
                <i className="fas fa-plus mr-2" />
                Add
              </Button1>
            </div> */}
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none",
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              filterable
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              showPagination={showPagination}
              LoadingComponent={Loader}
              // onFetchData={onChangePageGetData}
              onPageChange={(pageIndex) => {
                setActivePage(pageIndex);
                getPagesList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={(sortProperties) => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getPagesList(sortProperties[0].id, sort, searchText, 1);
              }}
            />
          </div>
        </div>
      </div>
      <CommonModel
        modal={viewModal}
        viewModalCheck={true}
        headerData={"Subscription Plan Details"}
        button={
          <>
            <Button
              style={{ background: "#3ac47d", border: "none", color: "white" }}
              onClick={() => {
                setviewModal(!viewModal);
              }}
            >
              Close
            </Button>
          </>
        }
      >
        {viewData && (
          <div>
            <b>Storage: </b> {viewData.storage}
            <br />
            <br />
            <b>Price: </b> {viewData.price}
            <br />
            <br />
            <b>Duration: </b> {viewData.duration}
            <br />
            <br />
            <b>Status:</b> {viewData.status}
          </div>
        )}
      </CommonModel>
      {deleteOpen && (
        <CommonModel
          modal={deleteOpen}
          size="small"
          style={{ maxWidth: "300px", width: "50%" }}
          children={
            <Delete
              setLoader={setLoader}
              userToDEL={userToDEL}
              formAction={formAction}
              toggle={() => {
                setDeleteOpen(false);
              }}
            />
          }
        />
      )}
      <Toaster ref={toaster} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.auth,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, { logout })
)(SubscriptionTable);
