const authActions = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  ADMIN: "ADMIN",

  login: (data) => {
    return {
      type: authActions.LOGIN,
      isLogin: true,
      accessToken: data.token,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      admin_id: data.admin_id,
      photo: data.photo,
      adminType: data.adminType,
      admin_assignrole: data.admin_assignrole,
    };
  },

  logout: () => {
    return {
      type: authActions.LOGOUT,
      isLogin: false,
      accessToken: null,
    };
  },

  admin: (data) => {
    return {
      type: authActions.ADMIN,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      admin_id: data.admin_id,
      photo: data.photo,
      adminType: data.adminType,
      admin_assignrole: data.admin_assignrole,
    };
  },
};

export default authActions;
