import { withFormik } from "formik";
import * as Yup from "yup";

const phoneRegExp = RegExp(/^[1-9]{1}[0-9]{9}/);
const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    first_name: Yup.string().required("This field is required").nullable(),
    email: Yup.string().required("This field is required").email().trim().nullable(),
    phone: Yup.string().required("This field is required").max(10).matches(phoneRegExp, "Phone number is not valid"),
    gender: Yup.string().required("This field is required").nullable(),
    status: Yup.string().required("This field is required").nullable(),
    device_type: Yup.string().required("This field is required").nullable(),
  }),
  mapPropsToValues: props => ({
    id : props.editedData
      ? props && props.editedData && props.editedData.id
      : "",
    first_name : props.editedData
      ? props && props.editedData && props.editedData.first_name
      : "",
    email: props.editedData
      ? props && props.editedData && props.editedData.email
      : "",
    phone : props.editedData
      ? props && props.editedData && props.editedData.phone
      : "",
    gender : props.editedData
      ? props && props.editedData && props.editedData.gender
      : "",
    status : props.editedData
      ? props && props.editedData && props.editedData.status
      : "",
    device_type : props.editedData
      ? props && props.editedData && props.editedData.device_type
      : "",
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
