import React, { useEffect, useState, useRef } from "react";
import UserWrapper from "../StaticPage/page.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import Toaster from "../../components/common/Toaster";
import CommentSettingsForm from "../../components/Comments/CommentSettingsForm";
import Api from "helper/Api";

const CommentSettingsEdit = (props) => {
  const hasParam = props.match.params.id;

  const [editedData, setEditedData] = useState(null);
  const [loading, setloading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [loader, setLoader] = useState(true);

  const toaster = useRef();
  useEffect(() => {
    Api("GET", "cms-api/comments/settings")
      .then((res) => {
        setEditedData(res.data.data.rows[0]);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  }, []);

  const submitFormHandler = (data, action) => {
    if (action === "add") {
      // const params = {
      //   platform: data.platform,
      //   page_name_en: data.titleEnglish,
      //   page_name_fr: data.titleFrench,
      //   page_content_en: data.contentEn,
      //   page_content_fr: data.contentFr
      // };
      // StaticpageServices.add(params)
      //   .then(res => {
      //     if (res.data.status) {
      //       if (toaster.current) toaster.current.success(res.data.message);
      //       props.history.push("/staticpage");
      //     } else {
      //       if (toaster.current) toaster.current.error(res.data.message);
      //     }
      //   })
      //   .catch(err => {
      //     if (toaster.current) toaster.current.error(err.response);
      //   });
    } else if (action === "edit") {
      const params = {
        isActive: data.status === "active" ? true : false,
        characterLimit: Number(data.characterLimit),
      };
      setloading(true);
      setDisabled(true);
      Api("PUT", `cms-api/comments/settings`, params)
        .then((res) => {
          if (res.data.status) {
            if (toaster.current) toaster.current.success(res.data.message);
            props.history.push("/commentSettings");
            setloading(false);
            setDisabled(false);
          } else {
            if (toaster.current) toaster.current.error(res.data.message);
            setloading(false);
            setDisabled(false);
          }
        })
        .catch((err) => {
          if (toaster.current) toaster.current.error(err.response);
          setloading(false);
          setDisabled(false);
        });
    }
  };

  return (
    <UserWrapper {...props}>
      <div className="pos-relative">
        <PageTitle
          title="sidebar.commentSettings"
          className="plr-15"
          breadCrumb={[
            {
              name: "sidebar.commentSettings",
            },
            {
              name: hasParam ? "action.edit" : "action.add",
            },
          ]}
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/commentSettings")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            {/* <div className="flex-1 fs-16 demi-bold-text"> */}
            {/* <span className="hash"># </span> {hasParam ? "Edit" : "Add"} Page */}
            {/* <span className="hash"># </span> {hasParam ? "Edit" : "Add"} User */}
            {/* </div> */}
          </div>
          <div className="roe-card-body">
            <CommentSettingsForm
              onSubmit={submitFormHandler}
              editedData={editedData}
              action={hasParam ? "edit" : "add"}
              loading={loading}
              disabled={disabled}
              loader={loader}
            />
          </div>
        </div>
        <Toaster ref={toaster} />
      </div>
    </UserWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.themeChanger,
  };
};

export default connect(mapStateToProps, null)(CommentSettingsEdit);
