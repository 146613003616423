import React, { useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import formikEnhancer from "./validator";
import SmallLoader from "../common/SmallLoader";
import Loader from "components/common/Loader";
import Toaster from "../common/Toaster";
import Api from "../../helper/Api";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

const SubscriptionForm = (props) => {
  const toaster = useRef();

  const {
    handleChange,
    handleBlur,
    errors,
    // setFieldTouched,
    setFieldValue,
    touched,
    submitCount,
    // onSubmit,
    onAdd,
    onEdit,
    values,
    action,
  } = props;
  let { id } = useParams();

  useEffect(() => {
    if (id && action === "edit") {
      // ContentServices.view(id)
      Api("POST", `cms-api/subscription/retrive/${id}`).then((result) => {
        setFieldValue("storage", result.data.data.storage);
        setFieldValue("price", result.data.data.price);
        setFieldValue("duration", result.data.data.duration);
        setFieldValue("status", result.data.data.status);
      });
    } else if (id === null && action === "add") {
      setFieldValue("storage", "");
      setFieldValue("price", "");
      setFieldValue("duration", "");
    }
    // eslint-disable-next-line
  }, [id]);

  const Error = (props) => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  // const handleSubmit = () => {
  //   let { values, isValid, handleSubmit } = props;

  //   if (isValid) {
  //     toaster.current.success("Successfully Edit Admin");
  //     setTimeout(() => {
  //       onSubmit(values, action);
  //     }, 1000);
  //   }
  //   handleSubmit();
  // };
  const handleSubmit = (e) => {
    let { isValid, handleSubmit, values } = props;
    // e.preventDefault();
    if (action === "add" && isValid) {
      onAdd(values, action);
    }
    if (action === "edit" && isValid) {
      onEdit(values, action);
    }
    if (!isValid) {
      handleSubmit();
    }
  };

  return (
    <div>
      {!props.loader ? (
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="form-group">
            <label className="fs-16 medium-text">Storage</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="storage"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.storage}
              placeholder="storage"
            />
            <Error field="storage" />
          </div>
          <div className="form-group">
            <label className="fs-16 medium-text">Price</label>
            {/* <input
              type="text"
              id="price_type"            
              // value={values.price}
              size="1"
              /> */}
            <input
              // type="number"
              type="text"
              className="form-control react-form-input"
              min="1"
              id="price"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.price}
              placeholder="price"
            />
            <Error field="price" />
          </div>
          <div className="form-group radioType">
            <label className="fs-16 medium-text">Duration</label>
            <FormControl component="fieldset" >
              <RadioGroup
                row
                name="duration"
                value={values.duration}
              >
                <FormControlLabel
                  value="monthly"
                  control={<Radio />}
                  label="Monthly"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormControlLabel
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="yearly"
                  control={<Radio />}
                  label="Yearly"
                />
              </RadioGroup>
            </FormControl>
            <Error field="duration" />
          </div>
          { action === "edit" ? 
          <div className="form-group radioType">
            <label className="fs-16 medium-text">Status</label>
            <FormControl component="fieldset" >
              <RadioGroup
                row
                name="status"
                value={values.status}
              >
                <FormControlLabel
                  value="inactive"
                  control={<Radio />}
                  label="inactive"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormControlLabel
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="active"
                  control={<Radio />}
                  label="active"
                />
              </RadioGroup>
            </FormControl>
            <Error field="status" />
          </div> : false
          }
          
          <div>
            <div>
              {!props.loading && (
                <button
                  type="button"
                  className="c-btn c-info form-button fs-16 demi-bold-text"
                  style={{ maxWidth: "125px" }}
                  onClick={() => handleSubmit()}
                  // disabled= {props.disabled}
                  disabled={props.dirty === false}
                >
                  Submit
                </button>
              )}
              {props.loading && <SmallLoader />}
            </div>
          </div>
        </form>
      ) : (
        <Loader loading={props.loader} />
      )}

      <Toaster ref={toaster} />
    </div>
  );
};

export default formikEnhancer(false)(SubscriptionForm);
