import React, { useRef } from "react";
import { compose } from "redux";
import enhancer from "./commentSettingsValidator";
import "./StaticPageForm.css";
// import CKEditor from "ckeditor4-react";
// import { config } from "../../util/editorconfig";
import SmallLoader from "../common/SmallLoader";
import Loader from "components/common/Loader";
import Toaster from "../common/Toaster";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";

const CommentSettingsForm = (props) => {
  const toaster = useRef();

  const {
    handleChange,
    handleBlur,
    errors,
    // setFieldTouched,
    // setFieldValue,
    touched,
    submitCount,
    onSubmit,
    values,
    action,
  } = props;

  const Error = (props) => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;

    if (isValid) {
      toaster.current.success("Successfully Edited Settings ");
      setTimeout(() => {
        onSubmit(values, action);
      }, 1000);
    }
    handleSubmit();
  };

  return (
    <div>
      {!props.loader ? (
        <form>
          <div className="form-group">
            <label className="fs-16 medium-text">Character Limit</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="characterLimit"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.characterLimit}
              placeholder="Character Limit"
            />
            <Error field="Character Limit" />
          </div>
          <div className="form-group">
            <div className="radioType">
              <label className="fs-16 medium-text">Status</label>
              <FormControl component="fieldset">
                <RadioGroup row name="status" value={values.status}>
                  <FormControlLabel
                    value="active"
                    control={<Radio />}
                    label="Active"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FormControlLabel
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value="inactive"
                    control={<Radio />}
                    label="Inactive"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <Error field="status" />
          </div>

          <div>
            <div>
              {!props.loading && (
                <button
                  type="button"
                  className="c-btn c-info form-button fs-16 demi-bold-text"
                  style={{ maxWidth: "125px" }}
                  onClick={() => handleSubmit()}
                  // disabled= {props.disabled}
                  disabled={props.dirty === false}
                >
                  Submit
                </button>
              )}
              {props.loading && <SmallLoader />}
            </div>
          </div>
        </form>
      ) : (
        <Loader loading={props.loader} />
      )}

      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(enhancer)(CommentSettingsForm);
