import React, { useRef } from "react";
import Api from "helper/Api";
import Toaster from "../common/Toaster";

const deletModal = (props) => {
  const toaster = useRef();

  return (
    <div className="wp-100">
      <div
        className="fs-15 demi-bold-text cursor-pointer float-right"
        onClick={() => props.toggle()}
      >
        <i className="fa fa-times"></i>
      </div>
      <div className="text-center fs-20 font-weight-bolder">Are you sure ?</div>
      <br />

      <div className="d-flex justify-content-around">
        <button
            
            className="c-btn c-success" 
             onClick={() => {
              Api("PUT", `cms-api/user/delete/${props.userToDEL.user_id}`)
                .then((res) => {
                  toaster.current.success("Successfully Delete User");
                  setTimeout(() => {
                        document.location.reload(); // soft ref page
                        props.setLoader(false);
                      }, 1000);
                })
                .catch((err) => {
                    props.setLoader(false);
                });
             }}
        >
          Yes
        </button>
        <button className="c-btn c-danger" onClick={() => props.toggle()}>No</button>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default deletModal;
