import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
// import { compose } from "redux";
import formikEnhancer from "./validator";
// import "./StaticPageForm.css";
// import CKEditor from "ckeditor4-react";
// import { config } from "../../util/editorconfig";
import SmallLoader from "../common/SmallLoader";
import Loader from "components/common/Loader";
import Toaster from "../common/Toaster";
import Api from "../../helper/Api";
import PasswordField from "material-ui-password-field";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

const SubAdminForm = (props) => {
  const toaster = useRef();
  const [isShow, setIsShow] = useState(false);

  const {
    handleChange,
    handleBlur,
    errors,
    // setFieldTouched,
    setFieldValue,
    touched,
    submitCount,
    // onSubmit,
    onAdd,
    onEdit,
    values,
    action,
  } = props;
  let { id } = useParams();

  useEffect(() => {
    if (id && action === "edit") {
      // ContentServices.view(id)
      Api("POST", `cms-api/admin/retrive/${id}`).then((result) => {
        setFieldValue("firstName", result.data.data.firstName);
        setFieldValue("lastName", result.data.data.lastName);
        // setFormContent("");
        setFieldValue("email", result.data.data.email);
        // setFieldValue("password", result.data.data.password);
        setFieldValue("password", "");
        setFieldValue("adminType", result.data.data.adminType);
      });
    } else if (id === null && action === "add") {
      setFieldValue("firstName", "");
      setFieldValue("lastName", "");
      // setFormContent("");
      setFieldValue("email", "");
      setFieldValue("password", "");
      setFieldValue("adminType", "");
    }
    // eslint-disable-next-line
  }, [id]);

  const Error = (props) => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  // const handleSubmit = () => {
  //   let { values, isValid, handleSubmit } = props;

  //   if (isValid) {
  //     toaster.current.success("Successfully Edit Admin");
  //     setTimeout(() => {
  //       onSubmit(values, action);
  //     }, 1000);
  //   }
  //   handleSubmit();
  // };
  const handleSubmit = (e) => {
    let { isValid, handleSubmit, values } = props;
    // e.preventDefault();
    if (action === "add" && isValid) {
      onAdd(values, action);
    }
    if (action === "edit" && isValid) {
      onEdit(values, action);
    }
    if (!isValid) {
      handleSubmit();
    }
  };

  const onChange = () => {
    setIsShow(!isShow);
  };

  return (
    <div>
      {!props.loader ? (
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="form-group">
            <label className="fs-16 medium-text">First Name</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="firstName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
              placeholder="first name"
            />
            <Error field="firstName" />
          </div>
          <div className="form-group">
            <label className="fs-16 medium-text">Last Name</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="lastName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName}
              placeholder="last name"
            />
            <Error field="lastName" />
          </div>
          <div className="form-group">
            <label className="fs-16 medium-text">Email</label>
            <input
              disabled
              type="text"
              className="form-control react-form-input"
              id="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              placeholder="email"
            />
            <Error field="email" />
          </div>

          <div className="form-group radioType">
            <label className="fs-16 medium-text">Admin Type</label>
            {/* <input
              type="text"
              className="form-control react-form-input"
              id="adminType"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.adminType}
              placeholder="supAdmin/subAdmin"
            /> */}
            <FormControl component="fieldset" >
              <RadioGroup
                row
                // defaultValue="subAdmin"
                name="adminType"
                value={values.adminType}
              >
                <FormControlLabel
                  value="superAdmin"
                  control={<Radio />}
                  label="Super Admin"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormControlLabel
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="subAdmin"
                  control={<Radio />}
                  label="Sub Admin"
                />
              </RadioGroup>
            </FormControl>
            <Error field="adminType" />
          </div>
          <div className="form-group">
            <button type="button" className="c-btn c-info" onClick={() => onChange()}>
              Change Password
            </button>
          </div>
          {isShow ? (
            <div>
            <div className="form-group">
              <label className="fs-16 medium-text">Password</label>
              <PasswordField 
                className="form-control react-form-input"
                id="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                placeholder="password"
              />
              <Error field="password" />
            </div>
            <div className="form-group">
              <label className="fs-16 medium-text">Confirm Password</label>
              <PasswordField 
                className="form-control react-form-input"
                id="confirm_password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirm_password}
                placeholder="confirm password"
              />
              <Error field="confirm_password" />
            </div>
            </div>
            
          ) : null}

          <div>
            <div>
              {!props.loading && (
                <button
                  type="button"
                  className="c-btn c-info form-button fs-16 demi-bold-text"
                  style={{ maxWidth: "125px" }}
                  onClick={() => handleSubmit()}
                  // disabled= {props.disabled}
                  disabled={props.dirty === false}
                >
                  Submit
                </button>
              )}
              {props.loading && <SmallLoader />}
            </div>
          </div>
        </form>
      ) : (
        <Loader loading={props.loader} />
      )}

      <Toaster ref={toaster} />
    </div>
  );
};

export default formikEnhancer(false)(SubAdminForm);
