import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import { compose } from "redux";
import { connect } from "react-redux";
// import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import Loader from "../common/Loader";
import Moment from "react-moment";
import Toaster from "../common/Toaster";
import { TABLE } from "../../helper/constant";
import Api from "helper/Api";
import CommonModel from "../common/CommonModel";
import View from "./ViewModal";
import Delete from "./DeletModal";
import exportFromJSON from "export-from-json";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import { adminProfile } from "../../helper/constant";
import { store } from "redux/store";
import AuthActions from "redux/auth/actions";

const { logout } = AuthActions;

const UserPageTable = (props) => {
  const [tblData, settblData] = useState();
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("createdAt");
  const [sortBy, setSortBy] = useState("DESC");
  const [modal, setModal] = useState(false);
  const [userPageData, setUserPageData] = useState({});
  const [viewOpen, setViewOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isVerified, setIsVerified] = useState(undefined);
  const [userToDEL, setUserToDEL] = useState({});
  const [showPagination, setShowPagination] = useState(false);
  const toaster = useRef();
  var assignRole = props.admin_assignrole;

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1, isVerified);
    setPages(10);
  }, [searchText, isVerified]);

  const columns = [
    {
      Header: "Profile Image",
      accessor: "photo",
      // className: "text-center",
      className: "text-center",
      filterable: false,
      headerClassName: "react-table-header-class text-left",
      width: 120,
      Cell: (data) => {
        return (
          <>
            <img
              // src={uploadImage ? uploadImage : adminProfile}
              // src={data.row.photo ? `https://kyulebag.s3.us-east-2.amazonaws.com/kyulebag/user/${data.original.user_id}/${data.original.photo}` : adminProfile}
              // src={
              //   data.row.photo
              //     ? GCP_CONSTANT.GCP_URL +
              //       GCP_CONSTANT.GCP_BUCKET_NAME +
              //       "/" +
              //       GCP_CONSTANT.GCP_BUCKET_FOLDER +
              //       GCP_CONSTANT.GCP_USER_FOLDER +
              //       data.original.user_id +
              //       "/" +
              //       data.original.photo
              //     : adminProfile
              // }
              src={
                data.row.photo
                  ? data.original.photo
                  : adminProfile
              }
              alt=""
              // className="mtb-15"
              style={{
                width: "50px",
                borderRadius: "50%",
                height: "50px",
                // background: "#404040",
              }}
            />
          </>
        );
      },
    },
    {
      Header: "Name",
      accessor: "first_name",
      // className: "text-center",
      className: "text-left d-flex align-items-center",
      filterable: false,
      headerClassName: "react-table-header-class text-left",
      width: 130,
      Cell: (data) => {
        return (
          <>{data.value !== null && data.value !== "" ? data.value : "-"}</>
        );
      },
    },
    {
      Header: "Email",
      accessor: "email",
      className: "text-left d-flex align-items-center",
      filterable: false,
      headerClassName: "react-table-header-class text-left",
      Cell: (data) => {
        return (
          <>{data.value !== null && data.value !== "" ? data.value : "-"}</>
        );
      },
    },
    {
      Header: "Phone No",
      accessor: "phone",
      className: "text-left d-flex align-items-center",
      filterable: false,
      headerClassName: "react-table-header-class text-left",
      width: 120,
      Cell: (data) => {
        return (
          <>{data.value !== null && data.value !== "" ? data.value : "-"}</>
        );
      },
    },
    // {
    //   Header: "Gender",
    //   accessor: "gender",
    //   className: "text-left d-flex align-items-center",
    //   filterable: false,
    //   headerClassName: "react-table-header-class text-left",
    //   width: 100,
    //   Cell: (data) => {
    //     return (
    //       <>{data.value !== null && data.value !== "" ? data.value : "-"}</>
    //     );
    //   },
    // },
    {
      Header: "Status",
      accessor: "status",
      className: "text-left d-flex align-items-center",
      filterable: false,
      headerClassName: "react-table-header-class text-left",
      width: 100,
      Cell: (data) => {
        return (
          <>{data.value !== null && data.value !== "" ? data.value : "-"}</>
        );
      },
    },
    // {
    //   Header: "Device-type",
    //   accessor: "device_type",
    //   className: "text-left d-flex align-items-center",
    //   filterable: false,
    //   width: 140,
    //   headerClassName: "react-table-header-class text-left",
    //   Cell: (data) => {
    //     return (
    //       <>{data.value !== null && data.value !== "" ? data.value : "-"}</>
    //     );
    //   },
    // },
    {
      Header: "Date",
      accessor: "createdAt",
      className: "text-left d-flex align-items-center",
      filterable: false,
      headerClassName: "react-table-header-class text-left",
      width: 130,
      Cell: (props) => (
        <div>
          <Moment format="MMM-DD-YYYY">{props.original.createdAt}</Moment>
        </div>
      ),
    },
    {
      Header: "Action",
      accessor: "action",
      className: "text-center d-flex align-items-center justify-content-center",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 130,
      Cell: (props) => {
      return (
        <div className="react-action-class d-flex">
        {assignRole[0].view === true ? 
            <Tooltip title="View" placement="top" arrow>
              <div className="fs-14 medium-text text-primary cursor-pointer mr-3">
                <i
                  className="fas fa-eye fa-lg"
                  onClick={() => {
                    setViewOpen(true);
                    setModal(true);
                    setUserPageData(props.original);
                  }}
                ></i>
              </div>
            </Tooltip> : false
        }
        {assignRole[0].edit === true ? 
            <Tooltip title="Edit" placement="top" arrow>
              {/* <div className="fs-14 medium-text text-success ml-3 mr-3 cursor-pointer" > */}
              <div className="fs-14 medium-text text-success cursor-pointer" >
                <i
                  className="far fa-edit fa-lg"
                  onClick={() => formAction("edit", props.original)}
                />
              </div>
            </Tooltip> : false 
        }
        {assignRole[0].delete === true ? 
            <Tooltip title="Delete" placement="top" arrow>
              <div className="fs-14 medium-text text-danger cursor-pointer ml-3">
                <i
                  className="fas fa-trash-alt fa-lg"
                  onClick={() => {
                    // formAction("delete", props.original);
                    setUserToDEL(props.original);
                    setDeleteOpen(true);
                  }}
                ></i>
              </div>
            </Tooltip> : false 
        }
          </div>
        );
      }, // Custom cell components!
    },
  ];

  const onExportClick = () => {
    Api("POST", "cms-api/dashboard/list").then((res) => {
      const fileName = "users-list";
      const exportType = exportFromJSON.types.csv;
      // const exportType = exportFromJSON.types.xls;
      exportFromJSON({ data: res.data.count.users.rows, fileName, exportType });
    });
  };

  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "add") {
      // props.history.push("/staticpage/add");
    } else if (action === "edit") {
      props.history.push(`/user-management/edit/${data.user_id}`);
    }
    // else if (action === "delete") {
    //   Api("PUT", `cms-api/user/delete/${data.user_id}`)
    //     .then((res) => {
    //       document.location.reload(); // soft ref page
    //       setLoader(false);
    //     })
    //     .catch((err) => {
    //       setLoader(false);
    //     });
    // }
  };

  function getList(
    field = "createdAt",
    sortBy = "DESC",
    search = "",
    page = "1"
  ) {
    const params = {
      sort_by: field,
      order: sortBy,
      search_name: search,
      page: page,
      is_verified: isVerified,
    };
    setLoader(true);

    // Api("POST", "auth/static-content/list", params)
    Api("POST", "cms-api/user/list", params)
      .then((res) => {
        if (res.data.status) {
          settblData(res.data.data.rows);
          if (res.data.data.count > 10) {
            setShowPagination(true);
          } else {
            setShowPagination(false);
          }
          setPages(Math.ceil(res.data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          settblData(res.data.data.rows);
          setShowPagination(false);
          setLoader(false)
          if (toaster.current) toaster.current.error(res.data.message);
        }
      })
      .catch((err) => {
        if (err.response.status === 500) {
          if (toaster.current) toaster.current.error(err.response.data.message);
          setTimeout(() => {
            props.history.push("/dashboard");
          }, 1000);
        }
        if (err.response.status === 401) {
          if (toaster.current) toaster.current.success("You have been logged out since you were idle for more than 30 mins.");
          // alert("You have been logged out since you were idle for more than 30 mins.");
          setTimeout(() => {
            store.dispatch(logout());
            props.history.push("/login");
          }, 2000);
        }
      });
  }

  // const onDeletelist = () => {
  //   Api("POST", "cms-api/user/dellist")
  //   .then((res) => {
  //     if (res.data.status) {
  //       settblData(res.data.data.rows);
  //       setPages(Math.ceil(res.data.data.count / TABLE.LIMIT));
  //       setLoader(false);
  //     } else {
  //       if (toaster.current) toaster.current.error(res.data.message);
  //     }
  //   })
  //   .catch((err) => {
  //     if (toaster.current) toaster.current.error(err.response);
  //   });
  // };
  const handleChange = (event) => {
    // setIsVerified(event.target.value);
    setActivePage(0);
    setIsVerified(
      event.target.value === 1 ? "1" : event.target.value === 0 ? "0" : ""
    );
    // var is_verified = event.target.value === "active" ? 1 : event.target.value === "inactive" ? 0 : null;
    // getList(
    //   field ,
    //   sortBy,
    //   searchText,
    //   pages,
    //   is_verified,
    // );
  };

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header justify-content-between">
            {/* <div className="fs-16 demi-bold-text" /> */}
            <div className="d-flex flex-col">
              <button
                // sx={{ height: 35 }}
                className="e-btn d-flex align-items-center"
              >
                <FileDownloadOutlinedIcon onClick={onExportClick} />
                <h6 onClick={onExportClick}>Export</h6>
              </button>

              <FormControl
                sx={{
                  m: "8px",
                  mt: "8.5px",
                  mb: "1px",
                  /*marginRight:"200px",*/ minWidth: 120,
                  legend: { display: "none" },
                  textDecoration: "none",
                  border: "none",
                  // outlineColor: "none",
                }}
              >
                <Select
                  // className="form-control react-form-input"
                  value={isVerified}
                  onChange={handleChange}
                  displayEmpty
                  sx={{
                    height: 33,
                    mt: "2px",
                    mb: "7px",
                    border: "none",
                    textDecoration: "none",
                  }}
                  placeholder="filter"
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value={undefined}>
                    {/* {" "} */}
                    <em>default</em>
                  </MenuItem>
                  <MenuItem value={1}>Verified User</MenuItem>
                  <MenuItem value={0}>Unverified User</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <input
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  setLoader(true);
                  setActivePage(0);
                }}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
              />
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none",
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              filterable
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              showPagination={showPagination}
              LoadingComponent={Loader}
              // onFetchData={onChangePageGetData}
              onPageChange={(pageIndex) => {
                setActivePage(pageIndex);
                getList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={(sortProperties) => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, searchText, 1);
              }}
            />
          </div>

          {viewOpen && (
            <CommonModel
              modal={modal}
              size="small"
              style={{ maxWidth: "600px", width: "70%" }}
              children={
                <View
                  userPageData={userPageData}
                  toggle={() => {
                    setModal(false);
                  }}
                />
              }
            />
          )}
          {deleteOpen && (
            <CommonModel
              modal={deleteOpen}
              size="small"
              style={{ maxWidth: "300px", width: "50%" }}
              children={
                <Delete
                  // userPageData={userPageData}
                  setLoader={setLoader}
                  userToDEL={userToDEL}
                  // formAction={formAction}
                  toggle={() => {
                    setDeleteOpen(false);
                  }}
                />
              }
            />
          )}
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    admin_assignrole: state.auth.admin_assignrole,
    ...state.themeChanger,
  };
};
export default compose(
  withRouter,
  connect(mapStateToProps),
)(UserPageTable);
// withRouter(UserPageTable);
