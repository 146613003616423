/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
// import ContentServices from "../../api/ContentServices";
import Api from "../../helper/Api";
import ReactHtmlParser from "html-react-parser";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import Moment from "react-moment";
import { TABLE } from "../../helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import AuthActions from "redux/auth/actions";
import Tooltip from "@mui/material/Tooltip";
import CommonModel from "../common/CommonModel";
import Delete from "../Announcements/DeleteModal";
import moment from "moment";
import { store } from "redux/store";

// import ResendNotification from "./ResendModal";
// import CommonModel from "../common/CommonModel1";

const { logout } = AuthActions;

const AnnouncementTable = (props) => {
  const toaster = useRef();
  const [tblData, settblData] = useState();
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("createdAt");
  const [sortBy, setSortBy] = useState("DESC");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [announcementToDEL, setAnnouncementToDEL] = useState({});

  // const [viewModal, setviewModal] = useState(false);
  // const [viewData, setViewData] = useState(null);
  const [showPagination, setShowPagination] = useState(false);
  //   const [resendNotification, setResendNotification] = useState({});
  //   const [resendOpen, setResendOpen] = useState(false);
  // var assignRole = props.admin_assignrole;

  useEffect(() => {
    setLoader(true);
    getPagesList(
      field,
      sortBy,
      searchText,
      activePage + 1,
      props.location.pathname === "/announcements"
    );
    setPages(10);
    // eslint-disable-next-line
  }, [searchText]);

  const exportAnnouncementsAsCSV = () => {
    const csvData = [['Content', 'Created Date']]

    if(!!tblData){
      tblData.forEach((each) => {
        const createdDateTime = moment(each.createdAt).format("YYYY-MM-DD HH:mm:ss")
        let tmp = [`"${each.content}"`, createdDateTime]
        csvData.push(tmp)
      })
    }else{
      return
    }

    function makeCSV(allRows) {
      return allRows.map((r) => r.join(',')).join('\n')
    }

    const announcements = document.getElementById('announcements')
    const blobObj = new Blob([makeCSV(csvData)])
    announcements.href = URL.createObjectURL(blobObj)
  }

  const getPagesList = (field, sortBy, searchText, activePage) => {
    const params = {
      sort_by: field,
      order: sortBy,
      search: searchText,
      page: activePage,
    };
    setLoader(true);
    // ContentServices.list(params)
    Api("POST", "cms-api/announcements/list", params)
      .then((result) => {
        if (result.data.status) {
          settblData(result.data.data.rows);
          if (result.data.data.count > 10) {
            setShowPagination(true);
          } else {
            setShowPagination(false);
          }
          setPages(Math.ceil(result.data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          settblData(result.data.data.rows);
          setShowPagination(false);
          setLoader(false);
          if (toaster.current) toaster.current.error(result.data.message);
        }
      })
      .catch((err) => {
        if (err.response.status === 500) {
          if (toaster.current) toaster.current.error(err.response.data.message);
          setTimeout(() => {
            props.history.push("/dashboard");
          }, 1000);
        }
        if (err.response.status === 401) {
          if (toaster.current) toaster.current.success("You have been logged out since you were idle for more than 30 mins.");
          // alert("You have been logged out since you were idle for more than 30 mins.");
          setTimeout(() => {
            store.dispatch(logout());
            props.history.push("/login");
          }, 2000);
        }
      });
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    getPagesList(field, sortBy, e.target.value, activePage + 1);
    setLoader(true);
    setActivePage(0);
  };

  // const resendHandler = (props) => {
  //   // setLoader(true)
  //   Api(
  //     "POST",
  //     `cms-api/email-notification/retrive/${props.email_notification_id}`
  //   )
  //     .then((res) => {
  //       toaster.current.success("Successfully Resend Notification");
  //       setTimeout(() => {
  //         // document.location.reload(); // soft ref page
  //         // setLoader(false);
  //       }, 1000);
  //     })
  //     .catch((err) => {
  //       // setLoader(false);
  //     });
  // };
  // const viewHandler = data => {
  //   setviewModal(true);
  //   // ContentServices.view(data.static_contents_id)
  //   Api("POST", `cms-api/admin/retrive/${data.admin_id}`)
  //   .then(result => {
  //     setViewData(result.data.data);
  //   });
  // };

  const formAction = (action, data) => {
    if (action === "add") {
      props.history.push(`/announcements/add`);
    }
    // else if (action === "edit") {
    //   props.history.push(`/notification/edit/${data.notification_id}`);
    // }
  };

  const columns = [
    // {
    //   Header: "Subject",
    //   className: "text-left pl-2",
    //   filterable: false,
    //   accessor: "subject",
    //   headerClassName: "react-table-header-class text-left pl-2",
    //   // width: 750,
    // },
    {
      Header: "Content",
      className: "text-left pl-2",
      filterable: false,
      accessor: "content",
      headerClassName: "react-table-header-class text-left pl-2",
      width: 750,
      style: { whiteSpace: "unset" },
      Cell: (props) => <div>{ReactHtmlParser(props.original.content)}</div>,
    },
    {
      Header: "Date",
      className: "text-left d-flex align-items-center",
      filterable: false,
      accessor: "createdAt",
      headerClassName: "react-table-header-class text-left pl-2",
      // width: 250,
      Cell: (props) => (
        <div>
          <Moment format="DD-MMM-YYYY h:mm:ss a ">
            {props.original.createdAt}
          </Moment>
        </div>
      ),
    },
    {
      Header: "Action",
      accessor: "action",
      className: "text-center d-flex align-items-center justify-content-center",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 130,
      Cell: (props) => {
        return (
          <div className="react-action-class d-flex">
            <Tooltip title="Delete" placement="top" arrow>
              <div className="fs-14 medium-text text-danger cursor-pointer ml-3">
                <i
                  className="fas fa-trash-alt fa-lg"
                  onClick={() => {
                    // formAction("delete", props.original);
                    setAnnouncementToDEL(props.original);
                    setDeleteOpen(true);
                  }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      }, // Custom cell components!
    },
    // {
    //   Header: "Action",
    //   accessor: "action",
    //   className: "text-center",
    //   headerClassName: "react-table-header-class",
    //   filterable: false,
    //   // width: 350,
    //   Cell: (props) => (
    //     <div>
    //     {assignRole[2].add === true ?
    //       <Tooltip title="Resend Notification" placement="top" arrow>
    //         <div className="fs-14 medium-text text-primary cursor-pointer">
    //           <i
    //             className="fas fa-share fa-lg"
    //             // onClick={() => viewHandler(props.original)}
    //             onClick={() => {
    //               // formAction("delete", props.original);
    //             //   setResendNotification(props.original);
    //             //   setResendOpen(true);
    //               // resendHandler(props.original);
    //             }}
    //           ></i>
    //         </div>
    //       </Tooltip> : "-"
    //     }
    //     </div>
    //   ),
    // },
  ];

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text" />{" "}
            <div className="mr-10">
              <input
                value={searchText}
                onChange={handleSearch}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
                // style={{ border: "2px solid #20b2aa" }}
              />
            </div>
            {/* {assignRole[2].add === true ?  */}
            <div>
              <Button
                className="c-btn ma-5"
                onClick={() => formAction("add", null)}
                style={{
                  backgroundColor: "#472b1c",
                  color: "#fff",
                }}
              >
                {/* <i className="fas fa-plus mr-2" /> */}
                <i className="fas fa-bell mr-2"></i>
                Add Announcement
                {/* {type.charAt(0).toUpperCase() + type.slice(1)} */}
              </Button>
            </div>
            <div>
              <a id='announcements' download='announcements.csv'>
                <Button
                  className="c-btn ma-5"
                  onClick={() => exportAnnouncementsAsCSV()}
                  style={{
                    backgroundColor: "#472b1c",
                    color: "#fff",
                  }}
                >
                  {/* <i className="fas fa-plus mr-2" /> */}
                  <i className="fas fa-file-export mr-2"></i>
                  Export
                  {/* {type.charAt(0).toUpperCase() + type.slice(1)} */}
                </Button>
              </a>
            </div>
            {/* : false } */}
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none",
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              filterable
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              showPagination={showPagination}
              LoadingComponent={Loader}
              // onFetchData={onChangePageGetData}
              onPageChange={(pageIndex) => {
                setActivePage(pageIndex);
                getPagesList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={(sortProperties) => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getPagesList(sortProperties[0].id, sort, searchText, 1);
              }}
            />
          </div>
        </div>
      </div>
      {/* {resendOpen && (
        <CommonModel
          modal={resendOpen}
          size="small"
          style={{ maxWidth: "300px", width: "50%" }}
          children={
            <ResendNotification
              setLoader={setLoader}
              resendNotification={resendNotification}
              formAction={formAction}
              toggle={() => {
                setResendOpen(false);
              }}
            />
          }
        />
      )} */}
      {/* <CommonModel
              modal={modal}
              size="small"
              style={{ maxWidth: "400px", width: "70%" }}
              children={
                <View
                  userPageData={userPageData}
                  toggle={() => {
                    setModal(false);
                  }}
                />
              }
            /> */}
      {/* <CommonModel
        modal={viewModal}
        toggle={() => {
          setviewModal(!viewModal);
        }}
        viewModalCheck={true}
        headerData={"Admin Details"}
      > */}
      {/* {viewData && (
          <div>
            <b>First Name: </b> <p>{viewData.firstName}</p>
            <br />
            <b>Last Name: </b> <p>{viewData.lastName}</p>
            <br />
            <b>Email: </b> <p>{viewData.email}</p>
            <br />
            <b>Admin Type:</b> <p>{viewData.adminType}</p>
          </div>
        )} */}
      {/* </CommonModel> */}
      {deleteOpen && (
            <CommonModel
              modal={deleteOpen}
              size="small"
              style={{ maxWidth: "300px", width: "50%" }}
              children={
                <Delete
                  // userPageData={userPageData}
                  setLoader={setLoader}
                  announcementToDEL={announcementToDEL}
                  // formAction={formAction}
                  toggle={() => {
                    setDeleteOpen(false);
                  }}
                />
              }
            />
          )}
      <Toaster ref={toaster} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    admin_assignrole: state.auth.admin_assignrole,
    ...state.auth,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, { logout })
)(AnnouncementTable);
