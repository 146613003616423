import React from "react";
import PageWrapper from "../StaticPage/page.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import NotificationTable from "../../components/EmailNotification/NotificationTable";

const NotificationView = props => {
  return (
    <PageWrapper {...props}>
      <PageTitle title="sidebar.emailNotifications" className="plr-15" />
      <div className="user-tbl">
        <NotificationTable />
      </div>
    </PageWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default compose(withRouter, connect(mapStateToProps, null))(NotificationView);