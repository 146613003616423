import React from "react";
import PageWrapper from "../UserPageManagement/user.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import SubscriptionTable from "../../components/Subscription/SubscriptionTable";

const SubscriptionView = props => {
  return (
    <PageWrapper {...props}>
      <PageTitle title="sidebar.storageplan" className="plr-15" />
      <div className="user-tbl">
        <SubscriptionTable />
      </div>
    </PageWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default compose(withRouter, connect(mapStateToProps, null))(SubscriptionView);