import React, { useRef } from "react";
import { compose } from "redux";
import enhancer from "./validator";
import "./StaticPageForm.css";
// import CKEditor from "ckeditor4-react";
// import { config } from "../../util/editorconfig";
import SmallLoader from "../common/SmallLoader";
import Loader from "components/common/Loader"
import Toaster from "../common/Toaster";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

const UserPageForm = (props) => {

  const toaster = useRef();

  const {
    handleChange,
    handleBlur,
    errors,
    // setFieldTouched,
    // setFieldValue,
    touched,
    submitCount,
    onSubmit,
    values,
    action,
  } = props;

  const Error = (props) => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;

    if (isValid) {
      toaster.current.success("Successfully Edit User");
      setTimeout(() => {
            onSubmit(values, action);
          }, 1000);
    }
    handleSubmit();
  };

  return (
    <div>

      {!props.loader ?
        <form>

          <div className="form-group">
            <label className="fs-16 medium-text">
              First Name
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="first_name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.first_name}
              placeholder="first name"
            />
            <Error field="first_name" />
          </div>
          <div className="form-group">
            <label className="fs-16 medium-text">
              Email
            </label>
            <input
              disabled
              type="text"
              className="form-control react-form-input"
              id="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              placeholder="enter email"
            />
            <Error field="email" />
          </div>
          <div className="form-group">
            <label className="fs-16 medium-text">
              Phone No
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="phone"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone}
              // value={values.phone !== null ? values.phone : "null"}
              placeholder="phone"
            />
            <Error field="phone" />
          </div>
          <div className="form-group">
          <div className="radioType">
            <label className="fs-16 medium-text">
              Gender
            </label>
            {/* <input
              type="text"
              className="form-control react-form-input"
              id="gender"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.gender}
              placeholder="gender"
            /> */}
            <FormControl component="fieldset" >
              <RadioGroup
                row
                name="gender"
                value={values.gender}
              >
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label="Male"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormControlLabel
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="female"
                  control={<Radio />}
                  label="Female"
                />
                <FormControlLabel
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="other"
                  control={<Radio />}
                  label="Other"
                />
              </RadioGroup>
            </FormControl>
            </div>
            <Error field="gender" />
          </div>
          <div className="form-group">
          <div className="radioType">
            <label className="fs-16 medium-text">
              Status
            </label>
            {/* <input
              type="text"
              className="form-control react-form-input"
              id="status"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.status}
              placeholder="status"
            /> */}
            <FormControl component="fieldset" >
              <RadioGroup
                row
                name="status"
                value={values.status}
              >
                <FormControlLabel
                  value="active"
                  control={<Radio />}
                  label="Active"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormControlLabel
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="inactive"
                  control={<Radio />}
                  label="Inactive"
                />
              </RadioGroup>
            </FormControl>
            </div>
            <Error field="status" />
          </div>
          {/* <div className="form-group">
          <div className="radioType">
            <label className="fs-16 medium-text">
              Device Type
            </label> */}
            {/* <input
              type="text"
              // disabled
              className="form-control react-form-input"
              id="device_type"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.device_type}
              placeholder="device type"
            /> */}
            {/* <FormControl component="fieldset" >
              <RadioGroup
                row
                name="device_type"
                value={values.device_type}
              >
                <FormControlLabel
                  value="ios"
                  control={<Radio />}
                  label="Ios"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormControlLabel
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="android"
                  control={<Radio />}
                  label="Android"
                />
              </RadioGroup>
            </FormControl>
            </div>
            <Error field="device_type" />
          </div> */}

          {/* <div className="form-group">
            <label className="fs-16 medium-text">
              Content
            </label>
            <CKEditor
              id="contentEn"
              onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
              config={config}
              data={values.content}
              onChange={(event) => {
                setFieldValue("content", event.editor.getData());
              }}
              onBlur={(event) => {
                setFieldTouched("content", true, true);
              }}
            />
            <Error field="content" />
          </div> */}
          <div>
            <div>
              {!props.loading && 
              <button
                type="button"
                className="c-btn c-info form-button fs-16 demi-bold-text"
                style={{ maxWidth: "125px" }}
                onClick={() => handleSubmit()}
                // disabled= {props.disabled}
                disabled= {props.dirty === false }
              >
                Submit
              </button>}
              {
                props.loading && <SmallLoader />
              }
            </div>
          </div>
        </form>

        : <Loader loading={props.loader} />}

        <Toaster ref={toaster} />
    </div>

  );
};

export default compose(enhancer)(UserPageForm);
