import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = () =>
  withFormik({
    validationSchema: Yup.object().shape({
      subject: Yup.string().required("This field is required").nullable(),
      description: Yup.string()
        .required("This field is required")
        .nullable(),
      // lastName: Yup.string()
      //   .required("This field is required")
      //   .nullable(),
    }),
    mapPropsToValues: (props) => ({
      subject: props.editedData ? props && props.editedData && props.editedData.subject : "",
      description: props.editedData
        ? props && props.editedData && props.editedData.description
        : "",
    }),
    handleSubmit: (values) => {},
    displayName: "CustomValidationForm",
    enableReinitialize: true,
  });

export default formikEnhancer;
