import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    characterLimit: Yup.number()
      .required("This field is required")
      .nullable(),
    // status: Yup.required("This field is required").nullable(),
  }),
  mapPropsToValues: (props) => ({
    id: props.editedData
      ? props && props.editedData && props.editedData.commentSettingId
      : "",
    characterLimit: props.editedData
      ? props && props.editedData && props.editedData.characterLimit
      : "",

    status: props.editedData
      ? props && props.editedData && props.editedData.isActive === true
        ? "active"
        : "inactive"
      : "",
  }),
  handleSubmit: (values) => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true,
});

export default formikEnhancer;
