import React from "react";
import PageWrapper from "../UserPageManagement/user.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import SubAdminsPageTable from "../../components/Admins/SubAdminsTable";

const AdminsPageView = props => {
  return (
    <PageWrapper {...props}>
      <PageTitle title="sidebar.subadmin" className="plr-15" />
      <div className="user-tbl">
        <SubAdminsPageTable />
      </div>
    </PageWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default compose(withRouter, connect(mapStateToProps, null))(AdminsPageView);