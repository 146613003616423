import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = () =>
  withFormik({
    validationSchema: Yup.object().shape({
      // number_of_api: Yup.string()
      //   .required("This field is required")
      //   .nullable(),
      // price: Yup.string()
      //   .required("This field is required")
      //   .nullable(),
      // duration: Yup.string()
      //   .required("This field is required")
      //   .nullable(),
    }),
    mapPropsToValues: (props) => ({
    number_of_api: props.editedData
        ? props && props.editedData && props.editedData.number_of_api
        : "",
      price: props.editedData
        ? props && props.editedData && props.editedData.price
        : "",
      duration: props.editedData
        ? props && props.editedData && props.editedData.duration
        : "monthly",
    //   status: props.editedData
    //     ? props && props.editedData && props.editedData.status
    //     : "",
    }),
    handleSubmit: (values) => {},
    displayName: "CustomValidationForm",
    enableReinitialize: true,
  });

export default formikEnhancer;
