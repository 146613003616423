import Layout from "../layouts/DashboardLayout";
import { Login, /*Register,*/ ForgotPassword } from "views/pages/index";
import Otp from "../views/pages/authentication/Otp";
import twoStepAuthentication from "../views/pages/authentication/TwoStepAuthentication";
import ResetPassword from "../views/pages/authentication/ResetPassword";
// import AboutUs from "../views/pages/webview/AboutUs"

const indexRoutes = [
  { path: "/login", component: Login },
  // { path: "/register", component: Register },
  { path: "/twoStepAuthentication", component: twoStepAuthentication },  // 2FA Login Authentication
  { path: "/otp", component: Otp },
  { path: "/forgotPassword", component: ForgotPassword },
  { path: "/resetPassword", component: ResetPassword },
  // { path: "/aboutus", component: AboutUs },
  { path: "/", component: Layout },
];

export default indexRoutes;
