import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import Button from "components/button/Button";
import formikEnhancer from "./validator";
import Api from "../../helper/Api";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

const PageForm = (props) => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    values,
    submitCount,
    onAdd,
    setFieldValue,
    loading,
    onEdit,
    action,
  } = props;
  let { id } = useParams();

  let [formContent, setFormContent] = useState("");
  const config = {
    clipboard_defaultContentType : 'text',
    toolbar: {
      items: [
        "heading",
        "fontfamily",
        "fontsize",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "strikethrough",
        "underline",
        "blockQuote",
        "subscript",
        "superscript",
        "|",
        "link",
        // "imageUpload",
        "|",
        "alignment:left",
        "alignment:right",
        "alignment:center",
        "alignment:justify",
        "outdent",
        "indent",
        "|",
        "bulletedList",
        "numberedList",
        "todoList",
        "|",
        "code",
        "codeBlock",
        "|"
      ],
      shouldNotGroupWhenFull: true,
      autoParagraph: false,
      
    },
    // imageUpload: {
    //   toolbar: [
    //     'imageTextAlternative',
    //     'imageStyle:full',
    //     'imageStyle:side'
    //   ]
    // },
  };

  useEffect(() => {
    if (id && action === "edit") {
      // ContentServices.view(id)
      Api("POST", `cms-api/static-content/retrive/${id}`).then((result) => {
        setFieldValue("title", result.data.data.title);
        // setFieldValue("content", result.data.data.content);
        setFormContent(result.data.data.content);
        setFieldValue("type", result.data.data.type);
      });
    } else if (id === null && action === "add") {
      setFieldValue("title", "");
      // setFieldValue("content", "");
      setFormContent("");
      setFieldValue("type", "");
    }
    // eslint-disable-next-line
  }, [id]);

  const Error = (props) => {
    const field1 = props.field;
    if (
      (errors[field1] && touched[field1]) ||
      submitCount > 0 ||
      field1 === ""
    ) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };
  const onEditorStateChange = (event, editor) => {
    let content = editor.getData(event);
    setFormContent(content);
  };
  
  useEffect(() => {
    values.content = formContent;
   
  }, [formContent])

  const handleSubmit = (e) => {
    let { isValid, handleSubmit, values } = props;
    e.preventDefault();
    if (action === "add" && isValid) {
      onAdd(values, action);
    }
    if (action === "edit" && isValid) {
      onEdit(values, action);
    }
    if (!isValid) {
      handleSubmit();
    }
  };
  return (
    <>
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <div className="form-group">
          <label className="fs-16 medium-text">
            Title 
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="title"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.title}
            placeholder="Title"
            disabled
          />
          <Error field="title" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Content 
          </label>
          <div style={{ padding: "0", border: "1px solid #f1f1f1" }}>
            {/* <input
              type="text"
              className="form-control react-form-input"
              id="content"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.content}
              placeholder="content"
            /> */}
            <CKEditor
              onReady={editor => {
                editor.ui
                  .getEditableElement()
                  .parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.getEditableElement()
                  );
              }}
              onChange={(event, editor) => onEditorStateChange(event, editor)}
              data={formContent}
              editor={DecoupledEditor}
              config={config}
            />
          </div>
          <Error field="content" />
        </div>
        <div className="form-group">
        <div className="radioType">
          <label className="fs-16 medium-text">
            Type 
          </label>
          {/* <input
            // type="text"
            className="form-control react-form-input"
            id="type"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.type}
            placeholder="web/app"
          /> */}
          <FormControl component="fieldset" >
              <RadioGroup
                row
                // defaultValue="app"
                name="type"
                value={values.type}
              >
                <FormControlLabel
                  value="app"
                  control={<Radio />}
                  label="App"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormControlLabel
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value="web"
                  control={<Radio />}
                  label="Web"
                />
              </RadioGroup>
            </FormControl>
            </div>
          <Error field="type" />
        </div>
        <div>
          <Button
            type="submit"
            loading={loading}
            // onclick={() => handleSubmit()}
            className="c-btn c-info form-button fs-16 demi-bold-text mr-15"
            style={{ maxWidth: "125px" }}
            dataStyle="expand-right"
            // disabled= {false} 
            disabled= {props.dirty === false }
          >
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

export default formikEnhancer(PageForm);
