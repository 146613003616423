import styled from "styled-components";

const UserWrapper = styled.div`
  .Grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 0.5fr));
    grid-gap: 20px 40px;
    grid-auto-rows: 140px;
    background: white;
    margin: 15px;
  }
  .Card {
    background-color: #ae8f6a; /* fallback for old browsers */
    border-radius: 10px;
    transition: transform 0.2s;
    ${"" /* box-shadow: 0 3px 5px rgba(0,0,0,.02),0 0 2px rgba(0,0,0,.05),0 1px 4px rgba(0,0,0,.08)!important; */}
    height: 110px;
    cursor: pointer;
  }
  .Card:hover {
    transform: scale(1.05);
    ${"" /* opacity: 0.9; */}
    ${"" /* border: 2px solid rgba(82,61,203,.8);
    box-shadow: 0 3px 5px rgba(1,0,0,.02),0 0 2px rgba(1,0,0,.05),0 1px 4px rgba(1,0,0,.08)!important; */}
  }
  .Card-border {
    border-style: solid;
    border-width: 2px;
    ${'' /* border-color: #472b1c; */}
    background-color: #b39d82;
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
  }
  .Card-1 {
    background-color:#b39d82; /* fallback for old browsers */
    border-radius: 10px;
    transition: transform 0.2s;
    ${"" /* box-shadow: 0 3px 5px rgba(0,0,0,.02),0 0 2px rgba(0,0,0,.05),0 1px 4px rgba(0,0,0,.08)!important; */}
    boxShadow: 10px 20px 30px red !important;
    height: 110px;
    ${"" /* cursor: pointer; */}
  }
  .Card-1:hover {
    transform: scale(1.05);
    ${"" /* opacity: 0.9; */}
    ${"" /* border: 2px solid rgba(82,61,203,.8);
    box-shadow: 0 3px 5px rgba(1,0,0,.02),0 0 2px rgba(1,0,0,.05),0 1px 4px rgba(1,0,0,.08)!important; */}
  }
  .font-style {
    ${'' /* color: #fff; */}
    color: #200e05;
    text-align: center;
    font-size: 18px;
    font-weight: 100;
  }
  .font-style-2 {
    text-align: center !important;
    font-size: 30px;
    font-weight: 600;
    ${'' /* color: #fff; */}
    color: #200e05;
  }
  .Card-2 {
    background-color: #00b4db; /* fallback for old browsers */

    height: 110px;
    cursor: pointer;
  }
  .Card-2:hover {
    opacity: 0.9;
  }
  .Card-3 {
    background: #f7971e; /* fallback for old browsers */
    height: 110px;
    cursor: pointer;
  }
  .Card-3:hover {
    opacity: 0.9;
  }
  .Card-4 {
    background: #1488cc; /* fallback for old browsers */
    height: 110px;
    cursor: pointer;
  }
  .Card-4:hover {
    opacity: 0.9;
  }
  .Card-5 {
    background: #cc2b5e; /* fallback for old browsers */
    height: 110px;
    cursor: pointer;
  }
  .Card-5:hover {
    opacity: 0.9;
  }
  .Card-6 {
    background: #02aab0; /* fallback for old browsers */
    height: 110px;
    cursor: pointer;
  }
  .Card-6:hover {
    opacity: 0.9;
  }

  .Card-7 {
    background: #606060ff; /* fallback for old browsers */
    height: 110px;
    cursor: pointer;
  }
  .Card-7:hover {
    opacity: 0.9;
  }
`;

export default UserWrapper;
