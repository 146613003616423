import React, { useRef, useState } from "react";
import HeaderWrapper from "./header.style";

import IntlMessages from "util/intlMessages";

import { Popover, PopoverHeader, PopoverBody } from "reactstrap";

import { connect } from "react-redux";
import { compose } from "redux";
import AuthActions from "redux/auth/actions";
import { withRouter } from "react-router-dom";
import { AppName } from "helper/constant";
import Toaster from "../../components/common/Toaster";
import Api from "../../helper/Api";

import Avatar from "react-avatar";
// import AdminServices from "../../api/AdminServices";

const { logout } = AuthActions;

const Header = props => {

  const [profilePopover, setProfilePopover] = useState(false);
  const { drawerMiniMethod } = props;
  // const { drawerMiniMethod, mini } = props;

  const toaster = useRef();

  const adminSignout = () => {
    // props.logout();
    Api("DELETE", "cms-api/admin/logout")
    // AdminServices.logout();
    toaster.current.success("Signout succesfully");
    setTimeout(() => {
      props.logout();
      }, 1000);

    // if (props.isArtist) {
    //   AdminServices.logout();
    //   props.logout();
    //   props.artist({ isArtist: false });
    // } else if (props.isOrganiser) {
    //   AdminServices.logout();
    //   props.logout();
    //   props.organiser({ isOrganiser: false });
    // } else {
    //   AdminServices.logout();
    //   props.logout();
    // }
  };

  const profilePopovertoggle = () => {
    setProfilePopover(!profilePopover);
  };

  return (
    <HeaderWrapper {...props}>
      <div className="headerBack">
        <ul className="list-inline ma-0">
          <li className="list-inline-item feed-text-area-icon">
            <div className="drawer-handle-arrow ">
              {/* {mini ? (
                <button
                  className="top-header-icon c-btn"
                  onClick={drawerMiniMethod()}
                >
                  <i className="fas fa-arrow-right" />
                </button>
              ) : (
                <button
                  className="top-header-icon c-btn"
                  onClick={drawerMiniMethod()}
                >
                  <i className="fas fa-arrow-left" />
                </button>
              )} */}
            </div>
            <div className="mini-drawer-menu-icon" onClick={drawerMiniMethod()}>
              <i className="fas fa-bars" />{" "}
              <span className="app-name">{AppName}</span>
            </div>
          </li>
          <li className="list-inline-item feed-text-area-icon pull-right">
            <div
              id="profilePopover"
              onClick={profilePopovertoggle}
              className="top-header-profile-class"
            >
              {/* {props.photo || props.photo ===`https://kyulebag.s3.us-east-2.amazonaws.com/kyulebag/admin/admin_id/null` ? ( */}
              {props.photo ? (  
                <img
                  src={props.photo}
                  alt="loading"
                  style={{ height: "40px", width: "40px", borderRadius: "50%" }}
                />
              ) : (
                <Avatar
                  name={`${props.firstname} ${props.lastname}`}
                  size={36}
                  round={true}
                  // style={{ height: "40px", width: "40px", borderRadius: "50%", background:"rgb(71 43 28)" }}
                  // background={rgb(71 43 28)}
                />
              )}
            </div>
          </li>
          {/* 
          <li className="list-inline-item feed-text-area-icon pull-right mr-8">
            <button
              className="top-header-icon c-btn"
              id="notificationPopover"
              onClick={notificationPopovertoggle}
            >
              <i className="fas fa-bell" />
            </button>
          </li> */}
        </ul>
        {/* Grid Popover */}
        {/* 
                <Popover
                    className="header-popover-width"
                    placement="bottom"
                    isOpen={dashboardPopoverOpen}
                    target="dashboardPopover"
                    toggle={dashboardPopovertoggle}
                    trigger="hover"
                >
                    <PopoverHeader className="custom-popover-header">
                        Easy grid navigation inside dropdowns
                    </PopoverHeader>
                    <PopoverBody className="pa-0">
                        <div className="container-fluid grid-popover pa-0">
                            <div className="row no-gutters">
                                <div className="col-4 grey--text col-border pa-15">
                                    <div className="text-center">
                                        <i className="fas fa-user-graduate" />
                                        <div className="grid-text">User</div>
                                    </div>
                                </div>

                                <div className="col-4 grey--text col-border pa-15">
                                    <div className="text-center">
                                        <i className="fas fa-cog" />
                                        <div className="grid-text">Setting</div>
                                    </div>
                                </div>

                                <div className="col-4 grey--text col-border pa-15">
                                    <div className="text-center">
                                        <i className="fab fa-viacoin" />
                                        <div className="grid-text">
                                            Activity
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4 grey--text col-border pa-15">
                                    <div className="text-center">
                                        <i className="fas fa-magic" />
                                        <div className="grid-text">
                                            Automation
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4 grey--text col-border pa-15">
                                    <div className="text-center">
                                        <i className="fas fa-exclamation-circle" />
                                        <div className="grid-text">Error</div>
                                    </div>
                                </div>

                                <div className="col-4 grey--text col-border pa-15">
                                    <div className="text-center">
                                        <i className="fas fa-file-alt" />
                                        <div className="grid-text">Content</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </PopoverBody>
                </Popover>
                {/* Grid Popover */}
        {/* Notification Popover */}
        {/* <Popover
                    className="header-popover-width"
                    placement="bottom"
                    isOpen={notificationPopoverOpen}
                    target="notificationPopover"
                    toggle={notificationPopovertoggle}
                    trigger="hover"
                >
                    <PopoverHeader className="custom-popover-header text-center">
                        Notifications
                        <div className="mt-10">You have 21 Notifications</div>
                    </PopoverHeader>
                    <PopoverBody className="pa-0">
                        <div>
                            <Scrollbars
                                style={{
                                    height: "300px"
                                }}
                                autoHide
                            >
                                <ul className="list-inline pa-10 ma-0 ul-bottom-border flex" style={{alignItems: 'center'}}>
                                    <li className="list-inline-item pa-5 notification-popover-profile">
                                        <img src={friend1} alt="notify" />
                                    </li>
                                    <li className="list-inline-item pa-5">
                                        <i className="far fa-comment" />
                                        <span className="c-text-secondary">
                                            {" "}
                                            an hour ago
                                        </span>
                                        <div className="c-text-info">
                                            <strong>
                                                Alice Blue sent you a message.
                                            </strong>
                                        </div>
                                        <div className="c-text-danger">
                                            <small>
                                                Hii, johns how's work going on?
                                            </small>
                                        </div>
                                    </li>
                                </ul>

                                <ul className="list-inline pa-10 ma-0 ul-bottom-border flex" style={{alignItems: 'center'}}>
                                    <li className="list-inline-item pa-5 notification-popover-profile">
                                        <img src={friend2} alt="notify" />
                                    </li>
                                    <li className="list-inline-item pa-5">
                                        <i className="far fa-comment" />
                                        <span className="c-text-secondary">
                                            {" "}
                                            two hour ago
                                        </span>
                                        <div className="c-text-info">
                                            <strong>
                                                Alice Blue sent you a message.
                                            </strong>
                                        </div>
                                        <div className="c-text-danger">
                                            <small>
                                                Hii, johns how's work going on?
                                            </small>
                                        </div>
                                    </li>
                                </ul>

                                <ul className="list-inline pa-10 ma-0 ul-bottom-border flex" style={{alignItems: 'center'}}>
                                    <li className="list-inline-item pa-5 notification-popover-profile">
                                        <img src={friend3} alt="notify" />
                                    </li>
                                    <li className="list-inline-item pa-5">
                                        <i className="far fa-comment" />
                                        <span className="c-text-secondary">
                                            {" "}
                                            1 day ago
                                        </span>
                                        <div className="c-text-info">
                                            <strong>
                                                Alice Blue sent you a message.
                                            </strong>
                                        </div>
                                        <div className="c-text-danger">
                                            <small>
                                                Hii, johns how's work going on?
                                            </small>
                                        </div>
                                    </li>
                                </ul>

                                <ul className="list-inline pa-10 ma-0 ul-bottom-border flex" style={{alignItems: 'center'}}>
                                    <li className="list-inline-item pa-5 notification-popover-profile">
                                        <img src={friend4} alt="notify" />
                                    </li>
                                    <li className="list-inline-item pa-5">
                                        <i className="far fa-comment" />
                                        <span className="c-text-secondary">
                                            {" "}
                                            2 days ago
                                        </span>
                                        <div className="c-text-info">
                                            <strong>
                                                Alice Blue sent you a message.
                                            </strong>
                                        </div>
                                        <div className="c-text-danger">
                               setProfilePopover          </div>
                                    </li>
                                </ul>
                            </Scrollbars>
                        </div>
                    </PopoverBody>
                </Popover> */}{" "}
        {/* Notification popover */}
        {/* Language Popover */}
        {/* <Popover
                    className="language-popover-width"
                    placement="bottom"
                    isOpen={languagePopover}
                    target="languagePopover"
                    toggle={languagePopovertoggle}
                    trigger="hover"
                >
                    <PopoverHeader className="custom-popover-header">
                        Languages
                    </PopoverHeader>
                    <PopoverBody className="pa-0">
                        <div className="container-fluid grid-popover pa-0">
                            <ul className="list-inline plr-10 ma-0 language-list-hover">
                                <li className="list-inline-item pa-5">
                                    <img
                                        src={englishLang}
                                        alt="E"
                                        width="40px"
                                    />
                                </li>
                                <li className="list-inline-item pa-5">USA</li>
                            </ul>

                            <ul className="list-inline plr-10 language-list-hover ma-0">
                                <li className="list-inline-item pa-5">
                                    <img
                                        src={frenchLang}
                                        alt="E"
                                        width="40px"
                                    />
                                </li>
                                <li className="list-inline-item pa-5">
                                    FRENCH
                                </li>
                            </ul>

                            <ul className="list-inline plr-10 language-list-hover ma-0">
                                <li className="list-inline-item pa-5">
                                    <img
                                        src={chineseLang}
                                        alt="E"
                                        width="40px"
                                    />
                                </li>
                                <li className="list-inline-item pa-5">
                                    CHINESE
                                </li>
                            </ul>

                            <ul className="list-inline plr-10 language-list-hover ma-0">
                                <li className="list-inline-item pa-5">
                                    <img
                                        src={spanishLang}
                                        alt="E"
                                        width="40px"
                                    />
                                </li>
                                <li className="list-inline-item pa-5">
                                    SPANISH
                                </li>
                            </ul>

                            <ul className="list-inline plr-10 language-list-hover ma-0">
                                <li className="list-inline-item pa-5">
                                    <img
                                        src={italianLang}
                                        alt="E"
                                        width="40px"
                                    />
                                </li>
                                <li className="list-inline-item pa-5">
                                    ITALIAN
                                </li>
                            </ul>
                        </div>
                    </PopoverBody>
                </Popover>

                {/* Language popover */}
        {/* Profile Popover */}
        <Popover
          className="language-popover-width"
          placement="bottom"
          isOpen={profilePopover}
          target="profilePopover"
          toggle={profilePopovertoggle}
          trigger="hover"
        >
          <PopoverHeader className="custom-popover-header ">
            <ul className="list-inline ma-0">
              <li className="list-inline-item notification-popover-profile">
                {/* {props.photo || props.photo ===`https://kyulebag.s3.us-east-2.amazonaws.com/kyulebag/admin/admin_id/null` ? ( */}
                {props.photo ? (
                  <img
                    src={props.photo}
                    alt="loading"
                    style={{
                      height: "40px",
                      width: "40px",
                      borderRadius: "50%"
                    }}
                  />
                ) : (
                  <Avatar
                    name={`${props.firstname} ${props.lastname}`}
                    size={36}
                    round={true}
                  />
                )}
              </li>
              <li className="list-inline-item notification-popover-profile">
                {`${props.firstname} ${props.lastname}`}
              </li>
            </ul>
          </PopoverHeader>
          <PopoverBody className="pa-0">
            <div className="container-fluid grid-popover pa-0">
              <ul
                className="list-inline plr-10 ma-0 language-list-hover"
                onClick={() => {
                  props.history.push(`/editprofile`);
                }}
              >
                <li className="list-inline-item pa-5">
                  <i className="fas fa-user-alt" />
                </li>
                <li className="list-inline-item pa-5">
                  <IntlMessages id="header.editprofile" />
                </li>
              </ul>

              <ul
                className="list-inline plr-10 language-list-hover ma-0"
                onClick={() => {
                  props.history.push(`/changePassword`);
                }}
              >
                <li className="list-inline-item pa-5">
                  <i className="fas fa-cog" />
                </li>
                <li className="list-inline-item pa-5">
                  <div>Change Password</div>
                </li>
              </ul>

              <ul
                className="list-inline plr-10 language-list-hover ma-0"
                onClick={adminSignout}
              >
                <li className="list-inline-item pa-5">
                  <i className="fas fa-sign-out-alt" />
                </li>
                <li className="list-inline-item pa-5">
                  <IntlMessages id="header.signout" />
                </li>
              </ul>
            </div>
          </PopoverBody>
        </Popover>{" "}
        {/* Profile popover */}
        <Toaster ref={toaster} />
      </div>
    </HeaderWrapper>
  );
};
const mapStateToProps = state => {
  return {
    admin_id: state.auth.admin_id,
    firstname: state.auth.firstName,
    lastname: state.auth.lastName,
    photo: state.auth.photo,
    accessToken: state.auth.accessToken
  };
};
export default compose(
  withRouter,
  connect(mapStateToProps, { logout })
)(Header);
