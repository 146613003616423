import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    firstname: Yup.string().required("This field is required"),
    lastname: Yup.string().required("This field is required"),
    photo: Yup.string().required("This field is required").nullable(),
  }),
  mapPropsToValues: props => ({
    firstname: props.firstName,
    lastname: props.lastName,
    email: props.email,
    photo: props.photo
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true,
  isInitialValid:true,
});

export default formikEnhancer;
