import React from "react";
import PageWrapper from "./user.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
// import UserPageTable from "../../components/staticpage/UserPageTable";
import UserPageTable from "../../components/userlistpage/UserPageTable";

const UserPage = props => {
  return (
    <PageWrapper {...props}>
      <PageTitle title="sidebar.users" className="plr-15" />
      <div className="user-tbl">
        <UserPageTable {...props} />
      </div>
    </PageWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(UserPage);
