import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    title: Yup.string().required("This field is required"),
    content: Yup.string().required("This field is required"),
    type: Yup.string().required("This field is required"),
  }),
  // validate: values =>{
  //   const errors = {}
  //   if(values.title.length < 1) {
  //     errors.title = "title is required"
  //   }
  //   if(values.content.length < 1) {
  //     errors.content = "content is required"
  //   }
  //   return errors;
  // },
  mapPropsToValues: props => ({
    title: props.editedData ? props && props.editedData && props.editedData.title : "",
    content: props.editedData ? props && props.editedData && props.editedData.content : "",
    type: props.editedData ? props && props.editedData && props.editedData.type : "",
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
