import React from "react";
import Moment from "react-moment";
import { adminProfile } from "../../helper/constant";
import Button from "@mui/material/Button";

const viewModal = (props) => {
  let data = props.userPageData;
  return (
    <div className="wp-100">
      {/* <div
        className="fs-15 demi-bold-text cursor-pointer float-right"
        onClick={() => props.toggle()}
      >
        <i className="fa fa-times"></i>
      </div> */}
      {/* <div className="text-center fs-20 font-weight-bolder"> */}
      <div className="modal-header modal-title">
        <h5>User information</h5>
      </div>

      <div className="d-flex align-items-center">
        <div>
          <img
            // src={adminProfile}
            // src={
            //   props.userPageData.photo
            //     ? `https://kyulebag.s3.us-east-2.amazonaws.com/kyulebag/user/${props.userPageData.user_id}/${props.userPageData.photo}`
            //     : adminProfile
            // }
            // src={
            //   props.userPageData.photo
            //     ? GCP_CONSTANT.GCP_URL +
            //       GCP_CONSTANT.GCP_BUCKET_NAME +
            //       "/" +
            //       GCP_CONSTANT.GCP_BUCKET_FOLDER +
            //       GCP_CONSTANT.GCP_USER_FOLDER +
            //       props.userPageData.user_id +
            //       "/" +
            //       props.userPageData.photo
            //     : adminProfile
            // }
            src={
              props.userPageData.photo
                ? props.userPageData.photo
                : adminProfile
            }
            alt=""
            // className="mtb-15"
            style={{
              width: "220px",
              borderRadius: "5%",
              height: "220px",
              // background: "#404040",
            }}
          />
        </div>

        {/* <div className="d-flex">
        <div>
          <div style={{ fontWeight: "bolder", textAlign: "left" }}>First Name </div>
          <div style={{ fontWeight: "bolder", textAlign: "left" }}>Email </div>
          <div style={{ fontWeight: "bolder", textAlign: "left" }}>Phone No </div>
          <div style={{ fontWeight: "bolder", textAlign: "left" }}>Gender </div>
          <div style={{ fontWeight: "bolder", textAlign: "left" }}>Status </div>
          <div style={{ fontWeight: "bolder", textAlign: "left" }}>Device Type </div>
          <div style={{ fontWeight: "bolder", textAlign: "left" }}>Date </div>
        </div>
        <div>
          <div className="text-left ml-2">: {data.first_name !== null ? data.first_name : "-"}</div>
          <div className="text-left ml-2">: {data.email !== null ? data.email : "-"}</div>
          <div className="text-left ml-2">: {data.phone !== null ? data.phone : "-"}</div>
          <div className="text-left ml-2">: {data.gender !== null ? data.gender : "-"}</div>
          <div className="text-left ml-2">: {data.status !== null ? data.status : "-"}</div>
          <div className="text-left ml-2">: {data.device_type !== null ? data.device_type : "-"}</div>
          <div className="text-left ml-2">: <Moment format="MMM-DD-YYYY HH:mm:ss">{data.createdAt}</Moment> </div>
        </div>
      </div> */}

        <div className="row ml-2">
          <div className="col-12 pt-4">
            <div>
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                First Name :
              </div>
              <div className="text-left">
                {data.first_name !== null ? data.first_name : "-"}
              </div>
            </div>
          </div>
          <div className="col-12 pt-4">
            <div>
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                Email :
              </div>
              <div className="text-left">
                {data.email !== null ? data.email : "-"}
              </div>
            </div>
          </div>
          <div className="col-12 pt-4">
            <div>
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                Phone No :
              </div>
              <div className="text-left">
                {data.phone !== null ? data.phone : "-"}
              </div>
            </div>
          </div>
          <div className="col-12 pt-4">
            <div>
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                Gender :
              </div>
              <div className="text-left">
                {data.gender !== null ? data.gender : "-"}
              </div>
            </div>
          </div>
          <div className="col-12 pt-4">
            <div>
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                Status :
              </div>
              <div className="text-left">
                {data.status !== null ? data.status : "-"}
              </div>
            </div>
          </div>
          <div className="col-12 pt-4">
            <div>
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                Device Type :
              </div>
              <div className="text-left">
                {data.device_type !== null ? data.device_type : "-"}
              </div>
            </div>
          </div>
          <div className="col-12 pt-4">
            <div>
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                Date :
              </div>
              <Moment format="MMM-DD-YYYY HH:mm:ss">{data.createdAt}</Moment>
            </div>
          </div>

          {/* <div className="col-12 pt-4">
          <div>
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>Content</div>
            <div className="text-left" dangerouslySetInnerHTML={{ __html: data.content }} />
          </div>
        </div> */}
        </div>
      </div>
      <div className="modal-footer">
        <Button
          style={{ background: "#3ac47d", border: "none", color: "white" }}
          onClick={() => {
            props.toggle();
          }}
        >
          Close
        </Button>
      </div>
    </div>
  );
};

export default viewModal;
