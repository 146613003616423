import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
// import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
// import ContentServices from "../../api/ContentServices";
import Api from "../../helper/Api";
// import ReactHtmlParser from "html-react-parser";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import { TABLE } from "../../helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import AuthActions from "redux/auth/actions";
import CommonModel from "../common/CommonModel1";
import Delete from "./DeletModal";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import { store } from "redux/store";

const { logout } = AuthActions;

const RoleTable = (props) => {
  const toaster = useRef();
  const [tblData, settblData] = useState();
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("createdAt");
  const [sortBy, setSortBy] = useState("DESC");
  //   const [viewModal, setviewModal] = useState(false);
  //   const [viewData, setViewData] = useState(null);
  const [showPagination, setShowPagination] = useState(false);
  const [roleToDEL, setRoleToDEL] = useState({});
  const [deleteOpen, setDeleteOpen] = useState(false);

  useEffect(() => {
    setLoader(true);
    getRoleList(
      field,
      sortBy,
      searchText,
      activePage + 1,
      props.location.pathname === "/roles"
    );
    setPages(10);
  }, [searchText]);

  const getRoleList = (field, sortBy, searchText, activePage) => {
    const params = {
      sort_by: field,
      order: sortBy,
      search: searchText,
      page: activePage,
    };
    setLoader(true);
    Api("POST", "cms-api/admin-role/role-list", params)
      .then((result) => {
        if (result.data.status) {
          settblData(result.data.data.rows);
          setShowPagination(true);
          setPages(Math.ceil(result.data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          settblData(result.data.data.rows);
          setShowPagination(false);
          setLoader(false);
          if (toaster.current) toaster.current.error(result.data.message);
        }
      })
      .catch((err) => {
        if (err.response.status === 500) {
          if (toaster.current) toaster.current.error(err.response.data.message);
          setTimeout(() => {
            props.history.push("/dashboard");
          }, 1000);
        }
        if (err.response.status === 401) {
          if (toaster.current) toaster.current.success("You have been logged out since you were idle for more than 30 mins.");
          // alert("You have been logged out since you were idle for more than 30 mins.");
          setTimeout(() => {
            store.dispatch(logout());
            props.history.push("/login");
          }, 2000);
        }
      });
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    getRoleList(field, sortBy, e.target.value, activePage + 1);
    setLoader(true);
    setActivePage(0);
  };

  const formAction = (action, data) => {
    if (action === "add") {
      props.history.push(`/roles/add`);
    } else if (action === "edit") {
      props.history.push(`/roles/edit/${data.role_id}`);
    }
  };

  const columns = [
    {
      Header: "Role Name",
      className: "text-left pl-5",
      filterable: false,
      accessor: "module_name",
      headerClassName: "react-table-header-class text-left pl-5",
    },
    {
      Header: "Action",
      accessor: "action",
      className: "text-left pl-5",
      headerClassName: "react-table-header-class text-left pl-5",
      filterable: false,
      Cell: (props) => (
        <div>
          <Tooltip title="Edit" placement="top" arrow>
            <Button
              className="c-btn mr-10"
              onClick={() => {
                formAction("edit", props.original);
              }}
              style={{
                backgroundColor: "#3ac47d",
                color: "#fff",
                textAlign: "center",
              }}
            >
              <i className="fas fa-edit"></i>
              {/* edit */}
            </Button>
          </Tooltip>
          <Tooltip title="Delete" placement="top" arrow className="text-red">
            <Button
              className="c-btn c-danger mr-10"
              onClick={() => {
                setRoleToDEL(props.original);
                setDeleteOpen(true);
              }}
              style={{
                backgroundColor: "#3ac47d",
                color: "#fff",
                textAlign: "center",
              }}
            >
              <i class="fas fa-trash"></i>
              {/* Delete */}
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text" />{" "}
            <div className="mr-10">
              <input
                value={searchText}
                onChange={handleSearch}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text form-control"
              />
            </div>
            <div>
              <Button
                className="c-btn ma-5"
                onClick={() => formAction("add", null)}
                style={{
                  backgroundColor: "#472b1c",
                  color: "#fff",
                }}
              >
                <i className="fas fa-plus mr-10" />
                Add
              </Button>
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none",
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              filterable
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              showPagination={showPagination}
              LoadingComponent={Loader}
              onPageChange={(pageIndex) => {
                setActivePage(pageIndex);
                getRoleList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={(sortProperties) => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getRoleList(sortProperties[0].id, sort, searchText, 1);
              }}
            />
          </div>
        </div>
      </div>
      {deleteOpen && (
        <CommonModel
          modal={deleteOpen}
          size="small"
          style={{ maxWidth: "300px", width: "50%" }}
          children={
            <Delete
              setLoader={setLoader}
              roleToDEL={roleToDEL}
              formAction={formAction}
              toggle={() => {
                setDeleteOpen(false);
              }}
            />
          }
        />
      )}
      <Toaster ref={toaster} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.auth,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, { logout })
)(RoleTable);
