export const AppName = "KYULEBAG";
export const AppName1 = "kbag";
export const drawerWidth = "270px";
export const miniDrawerWidth = "80px";
export const themeSettingDrawerWidth = "300px";
export const chatDrawerWidth = "260px";
export const chatMiniDrawerWidth = "0px";
export const baseUrl = process.env.REACT_APP_BASE_URL;
console.log(process.env.REACT_APP_BASE_URL);
export const brownShoe = require("assets/images/brown-shoe.jpeg");
export const speaker = require("assets/images/speaker.jpeg");
export const loginBack = require("assets/images/loginback.jpg");
export const iconDemo = require("assets/images/Logo.png");
export const adminProfile = require("assets/images/adminProfile.png");
export const sidebarIcon = require("assets/images/Logo1.png")
export const kyulebagtextIcon = require("../assets/images/kyulebagfontIcon.png");
export const kbagIcon = require("../assets/images/kbag.png");
export const ForgotIcon = require("assets/images/forgotpassword.svg");
export const locakscreenBack = require("assets/images/lockscreen.jpg");
export const ProfileLockScreen = require("assets/images/profile.jpg");
export const errorback = require("assets/images/errorback.jpg");
export const feed1 = require("assets/images/feed1.jpg");
export const feed2 = require("assets/images/feed2.jpg");
export const friend1 = require("assets/images/friend1.jpg");
export const friend2 = require("assets/images/friend2.jpg");
export const friend3 = require("assets/images/friend3.jpg");
export const friend4 = require("assets/images/friend4.jpg");
export const friend5 = require("assets/images/friend5.jpg");
export const friend6 = require("assets/images/friend6.jpg");
export const media1 = require("assets/images/media1.jpeg");
export const media2 = require("assets/images/media2.jpeg");
export const media3 = require("assets/images/media3.jpeg");
export const media4 = require("assets/images/media4.jpeg");
export const media5 = require("assets/images/media5.jpeg");
export const media6 = require("assets/images/media6.jpeg");
export const media7 = require("assets/images/media7.jpeg");
export const media8 = require("assets/images/media8.jpeg");
export const media9 = require("assets/images/media9.jpeg");
export const sidebar1 = require("assets/images/sidebar1.jpg");
export const sidebar2 = require("assets/images/sidebar2.jpg");
export const sidebar3 = require("assets/images/sidebar3.jpg");
export const sidebar4 = require("assets/images/sidebar4.jpg");
export const sidebar5 = require("assets/images/sidebar5.jpg");
export const sidebar6 = require("assets/images/sidebar6.jpg");
export const sidebar7 = require("assets/images/sidebar7.jpg");
export const sidebar8 = require("assets/images/sidebar8.jpg");
export const Img6464 = require("assets/images/6464Img.svg");

export const GCP_CONSTANT = {
  GCP_URL: "https://storage.googleapis.com/",
  GCP_BUCKET_NAME: "kyulebag",
  GCP_BUCKET_FOLDER: "kyulebag/",
  GCP_USER_FOLDER: "user/",
  GCP_ADMIN_FOLDER: "admin/",
};

export const USERTYPE = {
  BUYER: "buyer",
  MERCHANT: "merchant",
};

export const SORT = {
  ASC: "ASC",
  DESC: "DESC",
};

export const TABLE = {
  LIMIT: 10,
};

export const DEFAULT = {
  FIELD: "createdAt",
  SEARCH: "",
  PAGE: 1,
  SORT: SORT.DESC,
  USERTYPE: USERTYPE.BUYER,
  ADDRESS_ID: 0,
};

export const ImageResolution = {
  HEIGHT: 100,
  WIDTH: 100,
};

export const WEEKDAYS = [
  {
    id: 1,
    name: "Monday",
  },
  {
    id: 2,
    name: "Tuesday",
  },
  {
    id: 3,
    name: "Wednesday",
  },
  {
    id: 4,
    name: "Thursday",
  },
  {
    id: 5,
    name: "Friday",
  },
  {
    id: 6,
    name: "Saturday",
  },
  {
    id: 7,
    name: "Sunday",
  },
];
export const DAYS = {
  MONDAY: WEEKDAYS[0],
  TUESDAY: WEEKDAYS[1],
  WEDNESDAY: WEEKDAYS[2],
  THURSDAY: WEEKDAYS[3],
  FRIDAY: WEEKDAYS[4],
  SATURDAY: WEEKDAYS[5],
  SUNDAY: WEEKDAYS[6],
};

export const getDays = (day_number) => {
  switch (day_number) {
    case DAYS.MONDAY.id:
      return DAYS.MONDAY.name;
    case DAYS.TUESDAY.id:
      return DAYS.TUESDAY.name;
    case DAYS.WEDNESDAY.id:
      return DAYS.WEDNESDAY.name;
    case DAYS.THURSDAY.id:
      return DAYS.THURSDAY.name;
    case DAYS.FRIDAY.id:
      return DAYS.FRIDAY.name;
    case DAYS.SATURDAY.id:
      return DAYS.SATURDAY.name;
    case DAYS.SUNDAY.id:
      return DAYS.SUNDAY.name;
    default:
      return "";
  }
};

export const substractArrays = (oldArray, newArray) => {
  let array = [];

  oldArray &&
    oldArray.forEach((oldElement) => {
      let flag = true;
      newArray &&
        newArray.forEach((newElement) => {
          if (oldElement === newElement) {
            flag = false;
          }
        });
      if (flag) array.push(oldElement);
    });
  return array;
};

// export const format24to12 = time => {
//   const value = time.split("")[0];
//   const hh = value[0];
//   const mm = value[1];
//   const ss = value[2];

//   if (hh <= 12) {
//     if (hh === 12) {
//       if (mm > 0) {
//         return;
//       }
//     }
//   } else {
//     return `${hh}:${mm} PM`;
//   }
// };
