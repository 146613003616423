import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter/*, Button*/ } from "reactstrap";
const CommonModal = ({
  modal,
  toggle,
  className,
  children,
  style,
  headerData,
  button,
  viewModalCheck,
}) => {
  return (
    <div>
      <div>
        <Modal
          centered
          isOpen={modal}
          className={className}
          toggle={toggle}
          style={style}
        >
          {viewModalCheck ? <ModalHeader>{headerData}</ModalHeader> : ""}
          <ModalBody>{children}</ModalBody>
          {viewModalCheck ? (
            <ModalFooter>
              {button}
            </ModalFooter>
          ) : (
            ""
          )}
        </Modal>
      </div>
    </div>
  );
};

export default CommonModal;
