import axios from "axios";
import { baseUrl } from "./constant";
import { store } from "redux/store";
// import AuthActions from "../redux/auth/actions";
// const { logout } = AuthActions;

const Api = (method, urlData, params) => {
  const url = `${baseUrl}${urlData}`
  const headers = {}
  if (store.getState().auth.accessToken) {
      headers["Authorization"] = store.getState().auth.accessToken
  }
  let axiosObj = {
      method: method,
      url: url,
      data: params,
      headers: headers
  }

  return axios(axiosObj)
  // return new Promise((resolve, reject) => {
  //   const url = `${baseUrl}${urlData}`;
  //   const headers = {};

  //   if (store.getState().auth.accessToken) {
  //     headers["Authorization"] = "Bearer " + store.getState().auth.accessToken;
  //   }
  //   let axiosObj = {
  //     method: method,
  //     url: url,
  //     data: params,
  //     headers: headers,
  //   };
  //   axios(axiosObj)
  //     .then((response) => {
  //       resolve(response);
  //     })
  //     .catch((error) => {
  //       if (error.response.status === 401) {
  //         store.dispatch(logout());
  //       }
  //       reject(error);
  //     });
  // });
};

export default Api;
