import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button1 from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import Api from "../../helper/Api";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import { TABLE } from "../../helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
// import AdminServices from "api/AdminServices";
import AuthActions from "redux/auth/actions";
import CommonModel from "../common/CommonModel1";
import Delete from "./DeletModal";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import { Checkbox } from "@mui/material";
import { adminProfile } from "../../helper/constant";
import { store } from "redux/store";
// import CommonModel1 from "../common/CommonModel";
// import { Button1 } from "reactstrap";

const { logout } = AuthActions;

const AdminsPageTable = (props) => {
  const toaster = useRef();
  const [tblData, settblData] = useState();
  const [rolesData, setRolesData] = useState();
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("createdAt");
  const [sortBy, setSortBy] = useState("DESC");
  const [viewModal, setviewModal] = useState(false);
  const [viewData, setViewData] = useState(null);
  const [viewRolesModel, setViewRolesModal] = useState(false);
  const [showPagination, setShowPagination] = useState(false);
  const [userToDEL, setUserToDEL] = useState({});
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [checkData, setCheckData] = useState([]);
  const [adminId, setAdminId] = useState();
  const [adminRoles, setAdminRoles] = useState();

  useEffect(() => {
    setLoader(true);
    getPagesList(
      field,
      sortBy,
      searchText,
      activePage + 1,
      props.location.pathname === "/sub-admin"
    );
    setPages(10);
    // eslint-disable-next-line
  }, [searchText]);

  // const userSignout = () => {
  //   AdminServices.logout();
  //   props.logout();
  // };

  const getPagesList = (field, sortBy, searchText, activePage) => {
    const params = {
      sort_by: field,
      order: sortBy,
      search_name: searchText,
      page: activePage,
    };
    setLoader(true);
    // ContentServices.list(params)
    Api("POST", "cms-api/admin/sub-admins", params)
      .then((result) => {
        if (result.data.status) {
          settblData(result.data.data.rows);
          if (result.data.data.count > 10) {
            setShowPagination(true);
          } else {
            setShowPagination(false);
          }
          setPages(Math.ceil(result.data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          // settblData(result.data.data.rows);
          // setShowPagination(false);
          setLoader(false);
          if (toaster.current) toaster.current.error(result.data.message);
        }
      })
      .catch((err) => {
        if (err.response.status === 500) {
          if (toaster.current) toaster.current.error(err.response.data.message);
          setTimeout(() => {
            props.history.push("/dashboard");
          }, 1000);
        }
        if (err.response.status === 401) {
          if (toaster.current) toaster.current.success("You have been logged out since you were idle for more than 30 mins.");
          // alert("You have been logged out since you were idle for more than 30 mins.");
          setTimeout(() => {
            store.dispatch(logout());
            props.history.push("/login");
          }, 2000);
        }
      });
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    getPagesList(field, sortBy, e.target.value, activePage + 1);
    setLoader(true);
    setActivePage(0);
  };

  const viewHandler = (data) => {
    setviewModal(true);
    // ContentServices.view(data.static_contents_id)
    Api("POST", `cms-api/admin/retrive/${data.admin_id}`).then((result) => {
      setViewData(result.data.data);
    });
  };

  const viewRoles = (data) => {
    setViewRolesModal(true);
    Api("POST", "cms-api/admin-role/role-list").then((result) => {
      setRolesData(result.data.data.rows);
    });
    Api("POST", `cms-api/admin/retrive/${data.admin_id}`).then((result) => {
      setAdminId(result.data.data.admin_id);
      return result.data.data;
    });
    Api("POST", `cms-api/admin-role/assignrole-list/${data.admin_id}`).then(
      (result) => {
        setAdminRoles(
          result.data.data.rows.length
            ? result.data.data.rows
            : [{ role_id:1,add: false, delete: false, edit: false, view: false },{ role_id: 2,add: false, delete: false, edit: false, view: false },{ role_id: 3, add: false, delete: false, edit: false, view: false }]
        );
        return result.data.data.rows;
      }
    );
  };

  const onsubmit = () => {
    const params = {
      admin_id: adminId,
      checkData: checkData,
    };
    Api("POST", "cms-api/admin-role/assign-role", params).then((result) => {
      toaster.current.success("Successfully Assign Roles");
    });
    setViewRolesModal(!viewRolesModel);
  };

  const handleTblCheckbox = (e, name, id) => {
    let temp = checkData;
    let flag = true;
    setAdminRoles((prev) =>
      prev.map((item) => {
        let obj = {};
        // && item.name === name
        if (item.role_id === id) {
          obj = {
            ...item,
            [name]: e,
          };
        } else {
          obj = item;
        }
        return obj;
      })
    );
    if (flag) {
      temp.push({ role_id: id, name: name, e: e });
    }
    setCheckData(temp);
  };

  const formAction = (action, data) => {
    if (action === "add") {
      props.history.push(`/admin/add`);
    } else if (action === "edit") {
      props.history.push(`/sub-admin/edit/${data.admin_id}`);
    } else if (action === "delete") {
      Api("PUT", `cms-api/admin/delete/${data.admin_id}`)
        .then((res) => {
          document.location.reload(); // soft ref page
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const columns = [
    {
      Header: "Profile Image",
      accessor: "photo",
      // className: "text-center",
      className: "text-center",
      filterable: false,
      headerClassName: "react-table-header-class text-left",
      width: 120,
      Cell: (data) => {
        return (
          <>
            <img
              // src={uploadImage ? uploadImage : adminProfile}
              // src={data.row.photo ? `https://kyulebag.s3.us-east-2.amazonaws.com/kyulebag/admin/${data.original.admin_id}/${data.original.photo}` : adminProfile}
              // src={data.row.photo ?
              //   GCP_CONSTANT.GCP_URL +
              //   GCP_CONSTANT.GCP_BUCKET_NAME +
              //   "/" +
              //   GCP_CONSTANT.GCP_BUCKET_FOLDER +
              //   GCP_CONSTANT.GCP_ADMIN_FOLDER +
              //   data.original.admin_id +
              //   "/" +
              //   data.original.photo : adminProfile}
              src={data.row.photo ? data.original.photo : adminProfile}
              alt=""
              // className="mtb-15"
              style={{
                width: "50px",
                borderRadius: "50%",
                height: "50px",
                // background: "#404040",
              }}
            />
          </>
        );
      },
    },
    {
      Header: "First Name",
      className: "text-left pl-2 d-flex align-items-center",
      filterable: false,
      accessor: "firstName",
      headerClassName: "react-table-header-class text-left pl-2",
      width: 150,
    },
    {
      Header: "Last Name",
      className: "text-left pl-2 d-flex align-items-center",
      filterable: false,
      accessor: "lastName",
      headerClassName: "react-table-header-class text-left pl-2",
      width: 150,
    },
    {
      Header: "Email",
      className: "text-left pl-2 d-flex align-items-center",
      filterable: false,
      accessor: "email",
      headerClassName: "react-table-header-class text-left pl-2",
      width: 350,
    },
    {
      Header: "Admin Type",
      className: "text-left pl-2 d-flex align-items-center",
      filterable: false,
      accessor: "adminType",
      headerClassName: "react-table-header-class text-left pl-2",
      width: 200,
    },
    {
      Header: "Action",
      accessor: "action",
      className: " d-flex align-items-center justify-content-center",
      headerClassName: "react-table-header-class",
      filterable: false,
      // width: 280,
      Cell: (props) => {
        return (
          <div className="react-action-class d-flex">
            <Tooltip title="View" placement="top" arrow>
              <div className="fs-14 medium-text text-primary cursor-pointer">
                <i
                  className="fas fa-eye fa-lg"
                  onClick={() => viewHandler(props.original)}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Edit" placement="top" arrow>
              <div className="fs-14 medium-text text-success ml-3 mr-3 cursor-pointer">
                <i
                  className="far fa-edit fa-lg"
                  onClick={() => {
                    formAction("edit", props.original);
                  }}
                ></i>
              </div>
            </Tooltip>

            <Tooltip title="Delete" placement="top" arrow>
              <div className="fs-14 medium-text text-danger mr-3 cursor-pointer">
                <i
                  className="fas fa-trash-alt fa-lg"
                  onClick={() => {
                    // formAction("delete", props.original);
                    setUserToDEL(props.original);
                    setDeleteOpen(true);
                  }}
                ></i>
              </div>
            </Tooltip>

            <Tooltip title="Assign Roles" placement="top" arrow>
              <div className="fs-14 medium-text cursor-pointer">
                <i
                  className="fas fa-user-plus fa-lg roles"
                  onClick={() => {
                    viewRoles(props.original);
                    // formAction("edit", props.original);
                  }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  const rolescolumns = [
    {
      Header: "Module",
      className: "text-left",
      filterable: false,
      accessor: "module_name",
      headerClassName: "react-table-header-class text-left",
      width: 220,
    },
    {
      Header: "View",
      className: "text-center",
      filterable: false,
      headerClassName: "react-table-header-class",
      Cell: (row) => {
        return (
          <div>
            {adminRoles && adminRoles.length && (
              adminRoles.map(
                (data) =>
                  data.role_id === row.original.role_id && (
                    row.original.module_name === "User Management" ? 
                    <Checkbox
                      disabled
                      size="small"
                      className="chkbox-data"
                      name="chk"
                      checked={data.view}
                      onChange={(e) => {
                        handleTblCheckbox(
                          e.target.checked,
                          "view",
                          row.original.role_id
                        );
                      }}
                    /> : <span className="checkbox">-</span>
                     
                  )
              )
            ) }
          </div>
        );
      },
    },
    {
      Header: "Edit",
      className: "text-center",
      filterable: false,
      headerClassName: "react-table-header-class",
      Cell: (row) => {
        return (
          <div>
            {adminRoles &&
              adminRoles.length &&
              adminRoles.map(
                (data) =>
                  data.role_id === row.original.role_id && (
                    row.original.module_name !== "Email Notification" ?
                    <Checkbox
                      size="small"
                      className="chkbox-data"
                      name="chk"
                      checked={data.edit}
                      onChange={(e) => {
                        handleTblCheckbox(
                          e.target.checked,
                          "edit",
                          row.original.role_id
                        );
                      }}
                    /> :"-"
                  )
              )}
          </div>
        );
      },
    },
    {
      Header: "Add",
      className: "text-center",
      filterable: false,
      headerClassName: "react-table-header-class",
      Cell: (row) => {
        return (
          <div>
            {adminRoles &&
              adminRoles.length &&
              adminRoles.map(
                (data) =>
                  data.role_id === row.original.role_id && (
                    row.original.module_name === "Email Notification" ?
                    <Checkbox
                      size="small"
                      className="chkbox-data"
                      name="chk"
                      checked={data.add}
                      onChange={(e) => {
                        handleTblCheckbox(
                          e.target.checked,
                          "add",
                          row.original.role_id
                        );
                      }}
                    /> : "-"
                  )
              )}
          </div>
        );
      },
    },
    {
      Header: "Delete",
      className: "text-center",
      filterable: false,
      headerClassName: "react-table-header-class",
      Cell: (row) => {
        return (
          <div>
            {adminRoles &&
              adminRoles.length &&
              adminRoles.map(
                (data) =>
                  data.role_id === row.original.role_id && (
                    row.original.module_name === "User Management"  ?
                    <Checkbox
                      size="small"
                      className="chkbox-data"
                      name="chk"
                      checked={data.delete}
                      onChange={(e) => {
                        handleTblCheckbox(
                          e.target.checked,
                          "delete",
                          row.original.role_id
                        );
                      }}
                    /> : "-"
                  )
              )}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text" />{" "}
            <div className="mr-10">
              <input
                value={searchText}
                onChange={handleSearch}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
                // style={{ border: "2px solid #20b2aa" }}
              />
            </div>
            <div>
              <Button1
                className="c-btn ma-5"
                onClick={() => formAction("add", null)}
                style={{
                  backgroundColor: "#472b1c",
                  color: "#fff",
                }}
              >
                <i className="fas fa-plus mr-2" />
                Add
                {/* {type.charAt(0).toUpperCase() + type.slice(1)} */}
              </Button1>
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none",
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              filterable
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              showPagination={showPagination}
              LoadingComponent={Loader}
              // onFetchData={onChangePageGetData}
              onPageChange={(pageIndex) => {
                setActivePage(pageIndex);
                getPagesList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={(sortProperties) => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getPagesList(sortProperties[0].id, sort, searchText, 1);
              }}
            />
          </div>
        </div>
      </div>
      <CommonModel
        modal={viewModal}
        viewModalCheck={true}
        headerData={"Admin Details"}
        button={
          <>
            <Button
              style={{ background: "#3ac47d", border: "none", color: "white" }}
              onClick={() => {
                setviewModal(!viewModal);
              }}
            >
              Close
            </Button>
          </>
        }
      >
        {viewData && (
          <div className="d-flex align-items-center ">
          <div>
          <img
              // src={adminProfile}
              // src={viewData.photo ? `https://kyulebag.s3.us-east-2.amazonaws.com/kyulebag/admin/${viewData.admin_id}/${viewData.photo}` : adminProfile}
                // src={viewData.photo ?
                //   GCP_CONSTANT.GCP_URL +
                //   GCP_CONSTANT.GCP_BUCKET_NAME +
                //   "/" +
                //   GCP_CONSTANT.GCP_BUCKET_FOLDER +
                //   GCP_CONSTANT.GCP_ADMIN_FOLDER +
                //   viewData.admin_id +
                //   "/" +
                //   viewData.photo : adminProfile}
                src={viewData.photo ? viewData.photo : adminProfile}
              alt=""
              // className="mtb-15"
              style={{
                width: "200px",
                borderRadius: "5%",
                height: "200px",
                // background: "#404040",
              }}
            />
          </div>
          <div className="ml-3"> 
            <b>First Name: </b> <p>{viewData.firstName}</p>
            <br />
            <b>Last Name: </b> <p>{viewData.lastName}</p>
            <br />
            <b>Email: </b> <p>{viewData.email}</p>
            <br />
            <b>Admin Type:</b> <p>{viewData.adminType}</p>
          </div>
          </div>
        )}
      </CommonModel>
      <CommonModel
        modal={viewRolesModel}
        size="small"
        style={{ maxWidth: "800px", width: "100%" }}
        viewModalCheck={true}
        headerData={"Roles & Permissions"}
        button={
          <>
            <Button
              style={{
                background: "#3ac47d",
                border: "none",
                marginRight: "10px",
                color: "white",
              }}
              onClick={onsubmit}
            >
              Submit
            </Button>
            <Button
              style={{ background: "#e63946", border: "none", color: "white" }}
              onClick={() => {
                setViewRolesModal(!viewRolesModel);
              }}
            >
              Close
            </Button>
          </>
        }
        children={
          <ReactTable
            style={{
              border: "none",
              boxShadow: "none",
            }}
            data={rolesData}
            columns={rolescolumns}
            defaultPageSize={10}
            filterable
            minRows={2}
            manual
            showPagination={false}
          />
        }
      />

      {deleteOpen && (
        <CommonModel
          modal={deleteOpen}
          size="small"
          style={{ maxWidth: "300px", width: "50%" }}
          children={
            <Delete
              setLoader={setLoader}
              userToDEL={userToDEL}
              formAction={formAction}
              toggle={() => {
                setDeleteOpen(false);
              }}
            />
          }
        />
      )}
      <Toaster ref={toaster} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.auth,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, { logout })
)(AdminsPageTable);
