import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = () =>
  withFormik({
    validationSchema: Yup.object().shape({
      // subject: Yup.string().required("This field is required").nullable(),
      content: Yup.string()
        .required("This field is required")
        // .nullable(),
      // lastName: Yup.string()
      //   .required("This field is required")
      //   .nullable(),
    }),
    mapPropsToValues: (props) => ({
      content: props.editedData
        ? props && props.editedData && props.editedData.content
        : "",
    }),
    handleSubmit: (values) => {},
    displayName: "CustomValidationForm",
    enableReinitialize: true,
  });

export default formikEnhancer;
