import React, { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { compose } from "redux";
import formikEnhancer from "./validator";
// import "./StaticPageForm.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
// import { config } from "../../util/editorconfig";
import SmallLoader from "../common/SmallLoader";
import Loader from "components/common/Loader";
import Toaster from "../common/Toaster";
// import Api from "../../helper/Api";
import { connect } from "react-redux";
// import TextField from '@mui/material/TextField';

const AnnouncementForm = (props) => {
  const toaster = useRef();

  const {
    // handleChange,
    // handleBlur,
    errors,
    setFieldTouched,
    // setFieldValue,
    touched,
    submitCount,
    // onSubmit,
    onAdd,
    // onEdit,
    values,
    action,
  } = props;
  let { id } = useParams();

  let [formContent, setFormContent] = useState("");
  const config = {
    clipboard_defaultContentType: "text",
    toolbar: {
      items: [
        "heading",
        "fontfamily",
        "fontsize",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "strikethrough",
        "underline",
        "blockQuote",
        "subscript",
        "superscript",
        "|",
        "link",
        // "imageUpload",
        "|",
        "alignment:left",
        "alignment:right",
        "alignment:center",
        "alignment:justify",
        "outdent",
        "indent",
        "|",
        "bulletedList",
        "numberedList",
        "todoList",
        "|",
        "code",
        "codeBlock",
        "|",
      ],
      shouldNotGroupWhenFull: true,
      autoParagraph: false,
    },
    imageUpload: {
      resizeUnit: 'px',
      toolbar: [
          'imageStyle:inline',
          'imageStyle:wrapText',
          'imageStyle:breakText',
          '|',
          'toggleImageCaption',
          'imageTextAlternative',
      ],
    },
  };

  useEffect(() => {
    // if (id && action === "edit") {
    //   // ContentServices.view(id)
    //   Api("POST", `cms-api/admin/retrive/${id}`).then((result) => {
    //     // setFieldValue("firstName", result.data.data.firstName);
    //     // setFieldValue("lastName", result.data.data.lastName);
    //     // // setFormContent("");
    //     // setFieldValue("email", result.data.data.email);
    //     // // setFieldValue("password", result.data.data.password);
    //     // setFieldValue("password","");
    //     // setFieldValue("adminType", result.data.data.adminType);
    //   });
    // } else
    if (id === null && action === "add") {
      // setFieldValue("subject", "");
      setFormContent("content", "");
    }
    // eslint-disable-next-line
  }, [id]);

  const Error = (props) => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  const onEditorStateChange = (event, editor) => {
    let content = editor.getData(event);
    setFormContent(content);
  };

  useEffect(() => {
    values.content = formContent;
  }, [formContent]);

  // const handleSubmit = () => {
  //   let { values, isValid, handleSubmit } = props;

  //   if (isValid) {
  //     toaster.current.success("Successfully Edit Admin");
  //     setTimeout(() => {
  //       onSubmit(values, action);
  //     }, 1000);
  //   }
  //   handleSubmit();
  // };
  const handleSubmit = (e) => {
    let { isValid, handleSubmit, values } = props;
    props.values.admin_id = props.admin_id;
    props.values.is_announcement = true
    e.preventDefault();
    if (action === "add") {
      onAdd(values, action);
    }
    // if (action === "edit" && isValid) {
    //   onEdit(values, action);
    // }
    if (!isValid) {
      handleSubmit();
    }
  };

  return (
    <div>
      {!props.loader ? (
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          {/* <div className="form-group">
            <label className="fs-16 medium-text">
              Subject 
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="subject"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.subject}
              placeholder="Subject"
            />
            <Error field="subject" />
          </div> */}
          <div className="form-group">
            <label className="fs-16 medium-text">Announuncement</label>
            <div style={{ padding: "0", border: "1px solid #f1f1f1" }}>
              {/* <input
              type="text"
              className="form-control react-form-input"
              id="content"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.content}
              placeholder="Announuncement"
            /> */}
              <CKEditor
                onReady={(editor) => {
                  editor.ui
                    .getEditableElement()
                    .parentElement.insertBefore(
                      editor.ui.view.toolbar.element,
                      editor.ui.getEditableElement()
                    );
                }}
                onChange={(event, editor) => onEditorStateChange(event, editor)}
                onBlur={(event) => {
                  setFieldTouched("content", true, true);
                }}
                data={formContent}
                editor={DecoupledEditor}
                config={config}
                id="content"
                placeholder="Enter Content"
              />
            </div>
            <Error field="content" />
          </div>
          <div>
            <div>
              {!props.loading && (
                <button
                  type="button"
                  className="c-btn c-info form-button fs-16 demi-bold-text"
                  style={{ maxWidth: "125px" }}
                  onClick={(e) => handleSubmit(e)}
                  // disabled= {props.disabled}
                  // disabled={props.dirty === false}
                >
                  Send
                </button>
              )}
              {props.loading && <SmallLoader />}
            </div>
          </div>
        </form>
      ) : (
        <Loader loading={props.loader} />
      )}

      <Toaster ref={toaster} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.auth,
  };
};

export default compose(
  formikEnhancer(true),
  connect(mapStateToProps)
)(AnnouncementForm);
