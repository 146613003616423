import React, { useEffect, useState } from "react";
import UserWrapper from "./user.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import CommonServices from "../../api/CommonSeervices";
import Api from "../../helper/Api";
// import CountUp from "react-countup";
import CountUp from "react-countup";
import { store } from "redux/store";
import AuthActions from "../../redux/auth/actions";
const { logout } = AuthActions;

const Dashboard = (props, sidebarTheme) => {
  const [genUsers, setGenUsers] = useState(0);
  const [userItems, setUserItems] = useState(0);
  // const [content, setContens] = useState(0);
  //   const [activeChallenges, setActiveChallenges] = useState(0);
  //   const [services, setServices] = useState(0);
  useEffect(() => {
    // CommonServices.dashboard()
    Api("POST",`cms-api/dashboard/list`)
      .then((res) => {
        setGenUsers(res.data.count.users.count);
        setUserItems(res.data.count.user_items);
        // setContens(res.data.count.Totalcontent);
        // setActiveChallenges(res.data.count.activeChallenges);
        // setServices(res.data.count.services);
      })
      .catch((err) => {
        setTimeout(() => {
          store.dispatch(logout());
          props.history.push("/login");
        }, 1000);
      });
  }, []);
  const titleStyle = {
    // background: sidebarTheme.activeColor,
    color: sidebarTheme.textColorNew,
    boxShadow: `0 5px 20px 0 rgba(0,0,0,.2), 0 13px 24px -11px ${sidebarTheme.activeColor}`,
  };
  return (
    <UserWrapper {...props}>
      <PageTitle title="sidebar.dashboard" className="plr-15" />
      <div className="p-5 Grid">
        <div
          // className="Card p-3 text-center flex-column justify-content-center align-items-center"
          className="Card p-3 text-center Card-border "
          style={titleStyle}
          onClick={() => {
            props.history.push("/user-management");
          }}
        >
          <div className="font-style d-flex justify-content-center align-items-center">
            <i className="fas fa-users fs-22 mr-3"></i>
            ACTIVE USERS
          </div>
          <div>
            <CountUp className="font-style-2 " end={genUsers} duration={1} />
          </div>
          {/* <div className="font-style">
          ACTIVE USERS
          </div> */}
        </div>

        <div
          className="Card-1 p-3 text-center Card-border"
          onClick={() => {
            // props.history.push("/businessusers");
          }}
        >
          <div className="font-style d-flex justify-content-center align-items-center">
            <i className="fas fa-user-edit fs-22 mr-3"></i>
            USER ITEMS
          </div>

          <CountUp className="font-style-2 " end={userItems} duration={1} />
          {/* <div className="font-style">
          USER ITEMS
          <i class="fas fa-clipboard"></i>
          </div> */}
        </div>
        {/* <div
          className="Card-5 p-3 text-center"
          onClick={() => {
            props.history.push("/pages");
          }}
        >
          <CountUp className="font-style-2 " end={content} duration={1} />
          <div className="font-style">CONTENTS</div>
        </div> */}
        {/* <div
          className="Card-3 p-3 text-center"
          onClick={() => {
            props.history.push("/challenge");
          }}
        >
          <CountUp
            className="font-style-2 "
            end={activeChallenges}
            duration={15}
          />
          <div className="font-style">ACTIVE CHALLENGES</div>
        </div> */}
        {/* <div
          className="Card-2 p-3 text-center"
          onClick={() => {
            props.history.push("/service");
          }}
        >
          <CountUp className="font-style-2 " end={services} duration={15} />
          <div className="font-style">SERVICES OFFERED</div>
        </div> */}
      </div>
    </UserWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.themeChanger,
  };
};

export default connect(mapStateToProps, withRouter, null)(Dashboard);
