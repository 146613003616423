import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = (changePassword) =>
  withFormik({
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .required("This field is required")
        .nullable(),
      lastName: Yup.string()
        .required("This field is required")
        .nullable(),
      email: Yup.string()
        .required("This field is required")
        .email().trim()
        .nullable(),
      password: changePassword
        ? Yup.string()
            .min(6, "Password Must be atleast 6 char long")
            .required("This field is required")
            .nullable()
        : Yup.string()
            .min(6, "Password Must be atleast 6 char long")
            // .required("This field is required")
            .nullable(),
      confirm_password: changePassword
        ? Yup.string()
            .oneOf([Yup.ref("password"), null], "Password must Match")
            .required("This field is required")
            .nullable()
        : Yup.string()
            .oneOf([Yup.ref("password"), null], "Password must Match")
            // .required("This field is required")
            .nullable(),
      adminType: Yup.string()
        .required("This field is required")
        .nullable(),
    }),
    mapPropsToValues: (props) => ({
      firstName: props.editedData
        ? props && props.editedData && props.editedData.firstName
        : "",
      lastName: props.editedData
        ? props && props.editedData && props.editedData.firstName
        : "",
      // password: props.editedData
      //   ? props && props.editedData && props.editedData.password
      //   : "",
      adminType: props.editedData
        ? props && props.editedData && props.editedData.adminType
        : "",
    }),
    handleSubmit: (values) => {},
    displayName: "CustomValidationForm",
    enableReinitialize: true,
  });

export default formikEnhancer;
