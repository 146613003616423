import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
// import ContentServices from "../../api/ContentServices";
import Api from "../../helper/Api";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import Moment from "react-moment";
import { TABLE } from "../../helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import AuthActions from "redux/auth/actions";
import Tooltip from "@mui/material/Tooltip";
import { store } from "redux/store";

// import ResendNotification from "./ResendModal";

const { logout } = AuthActions;

const CommentSettingsTable = (props) => {
  const toaster = useRef();
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("createdAt");
  const [sortBy, setSortBy] = useState("DESC");

  // const [viewModal, setviewModal] = useState(false);
  // const [viewData, setViewData] = useState(null);
  const [showPagination, setShowPagination] = useState(false);
  //   const [resendNotification, setResendNotification] = useState({});
  //   const [resendOpen, setResendOpen] = useState(false);
  // var assignRole = props.admin_assignrole;

  useEffect(() => {
    setLoader(true);
    getCommentSettings();
    // field,
    // sortBy,
    // searchText,
    // activePage + 1,
    // props.location.pathname === "/comments"
    setPages(10);
    // eslint-disable-next-line
  }, [searchText]);

  const getCommentSettings = () => {
    // const params = {
    //   sort_by: field,
    //   order: sortBy,
    //   search: searchText,
    //   page: activePage,
    // };
    setLoader(true);
    // ContentServices.list(params)
    Api("GET", "cms-api/comments/settings")
      .then((result) => {
        if (result.data.status) {
          settblData(result.data.data.rows);
          if (result.data.data.count > 10) {
            setShowPagination(true);
          } else {
            setShowPagination(false);
          }
          setPages(Math.ceil(result.data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          settblData(result.data.data.rows);
          setShowPagination(false);
          setLoader(false);
          if (toaster.current) toaster.current.error(result.data.message);
        }
      })
      .catch((err) => {
        if (err.response.status === 500) {
          if (toaster.current) toaster.current.error(err.response.data.message);
          setTimeout(() => {
            props.history.push("/dashboard");
          }, 1000);
        }
        if (err.response.status === 401) {
          if (toaster.current) toaster.current.success("You have been logged out since you were idle for more than 30 mins.");
          // alert("You have been logged out since you were idle for more than 30 mins.");
          setTimeout(() => {
            store.dispatch(logout());
            props.history.push("/login");
          }, 2000);
        }
      });
  };

  // const handleSearch = (e) => {
  //   setSearchText(e.target.value);
  //   getCommentSettings(field, sortBy, e.target.value, activePage + 1);
  //   setLoader(true);
  //   setActivePage(0);
  // };

  // const resendHandler = (props) => {
  //   // setLoader(true)
  //   Api(
  //     "POST",
  //     `cms-api/email-notification/retrive/${props.email_notification_id}`
  //   )
  //     .then((res) => {
  //       toaster.current.success("Successfully Resend Notification");
  //       setTimeout(() => {
  //         // document.location.reload(); // soft ref page
  //         // setLoader(false);
  //       }, 1000);
  //     })
  //     .catch((err) => {
  //       // setLoader(false);
  //     });
  // };
  // const viewHandler = data => {
  //   setviewModal(true);
  //   // ContentServices.view(data.static_contents_id)
  //   Api("POST", `cms-api/admin/retrive/${data.admin_id}`)
  //   .then(result => {
  //     setViewData(result.data.data);
  //   });
  // };

  const formAction = (action, data) => {
    if (action === "add") {
      props.history.push(`/comments/add`);
    } else if (action === "edit") {
      props.history.push(`/commentSettings/edit/${data.commentSettingId}`);
    }
  };

  const columns = [
    {
      Header: "Character Limit",
      className: "text-left pl-2",
      filterable: false,
      sortable: false,
      accessor: "first_name + last_name",
      headerClassName: "react-table-header-class text-left pl-2",
      width: 200,
      style: { whiteSpace: "unset" },
      Cell: (props) => <div>{props.original.characterLimit}</div>,
    },
    {
      Header: "Comment Status",
      className: "text-left pl-2",
      filterable: false,
      sortable: false,
      accessor: "email",
      headerClassName: "react-table-header-class text-left pl-2",
      width: 300,
      style: { whiteSpace: "unset" },
      Cell: (props) => <div>{props.original.isActive === true ? "Active" : "Inactive"}</div>,
    },
    {
      Header: "Date",
      className: "text-left d-flex align-items-center",
      filterable: false,
      accessor: "createdAt",
      headerClassName: "react-table-header-class text-left pl-2",
      width: 250,
      Cell: (props) => (
        <div>
          <Moment format="DD-MMM-YYYY h:mm:ss a ">
            {props.original.createdAt}
          </Moment>
        </div>
      ),
    },
    {
      Header: "Action",
      accessor: "action",
      className: "text-center d-flex align-items-center justify-content-center",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 130,
      Cell: (props) => {
        return (
          <div className="react-action-class d-flex">
            <Tooltip title="Edit" placement="top" arrow>
              {/* <div className="fs-14 medium-text text-success ml-3 mr-3 cursor-pointer" > */}
              <div className="fs-14 medium-text text-success cursor-pointer">
                <i
                  className="far fa-edit fa-lg"
                  onClick={() => formAction("edit", props.original)}
                />
              </div>
            </Tooltip>
          </div>
        );
      }, // Custom cell components!
    },
  ];

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text" />{" "}
            {/* <div className="mr-10">
              <input
                value={searchText}
                onChange={handleSearch}
                type="text"
                placeholder="Search Comment..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
                // style={{ border: "2px solid #20b2aa" }}
              />
            </div> */}
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none",
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              filterable
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              showPagination={showPagination}
              LoadingComponent={Loader}
              // onFetchData={onChangePageGetData}
              onPageChange={(pageIndex) => {
                setActivePage(pageIndex);
                getCommentSettings(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={(sortProperties) => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getCommentSettings(sortProperties[0].id, sort, searchText, 1);
              }}
            />
          </div>
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    admin_assignrole: state.auth.admin_assignrole,
    ...state.auth,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, { logout })
)(CommentSettingsTable);
