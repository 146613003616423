// import { iconDemo } from "helper/constant";
export const sidebarData = [
  {
    name: "sidebar.dashboard",
    iconClass: "fas fa-tachometer-alt",
    routepath: "/dashboard",
  },
  {
    name: "sidebar.admin",
    iconClass: "fas fa-user-friends",
    child: [
      {
        listname: "sidebar.superadmin",
        routepath: "/admin",
        iconClass: "fas fa-user-tie ml-2",
      },
      {
        listname: "sidebar.subadmin",
        routepath: "/sub-admin",
        iconClass: "fas fa-user ml-2",
      },
      // {
      //   listname: "sidebar.role",
      //   routepath: "/roles",
      //   shortname: "Role"
      // },
    ],
    // name: "sidebar.admin",
  },
  {
    name: "sidebar.users",
    iconClass: "fa fa-clone",
    routepath: "/user-management",
  },
  // {
  //   name: "sidebar.subscription",
  //   iconClass: "fas fa-coins", /** fas fa-dollar-sign   fas fa-coins  fas fa-chart-pie */
  //   routepath: "/subscription",
  // },
  {
    name: "sidebar.subscription",
    iconClass: "fas fa-coins",
    child: [
      {
        listname: "sidebar.storageplan",
        routepath: "/subscription",
        iconClass: "fas fa-chart-pie ml-2",
      },
      {
        listname: "sidebar.AI-Subcription", // added last
        routepath: "/AI-subscription",
        iconClass: "fa fa-retweet ml-2",
      },
      {
        listname: "sidebar.payment",
        routepath: "/payment-transaction",
        iconClass: "fas fa-receipt ml-2",
      },
    ],
  },
  {
    name: "sidebar.contents",
    iconClass: "fas fa-file",
    routepath: "/pages",
  },
  {
    name: "sidebar.emailNotifications",
    iconClass: "fas fa-envelope",
    routepath: "/notification",
  },
  /** 21 march 2022 comment Announcement Module skeep as client requirement */
  {
    name: "sidebar.announcements",
    iconClass: "fas fa-bullhorn",
    routepath: "/announcements",
  },
  {
    name: "sidebar.commentsManagement",
    iconClass: "fas fa-comments",
    child: [
      {
        listname: "sidebar.commentSettings",
        routepath: "/commentSettings",
        iconClass: "fas fa-cog",
      },
      {
        listname: "sidebar.comments",
        routepath: "/comments",
        iconClass: "fas fa-comment",
      },
    ],
  },
];

export const sidebarSubAdminData = [
  {
    name: "sidebar.dashboard",
    iconClass: "fas fa-tachometer-alt",
    routepath: "/dashboard",
  },
  {
    name: "sidebar.users",
    iconClass: "fa fa-clone",
    routepath: "/user-management",
  },
  // {
  //   name: "sidebar.subscription",
  //   iconClass: "fas fa-dollar-sign", /**fas fa-coins */
  //   routepath: "/subscription",
  // },
  {
    name: "sidebar.subscription",
    iconClass: "fas fa-coins",
    child: [
      {
        listname: "sidebar.storageplan",
        routepath: "/subscription",
        iconClass: "fas fa-chart-pie ml-2",
      },
      {
        listname: "sidebar.AI-Subcription", // added last
        routepath: "/AI-subscription",
        iconClass: "fa fa-retweet ml-2",
      },
      {
        listname: "sidebar.payment",
        routepath: "/payment-transaction",
        iconClass: "fas fa-dollar-sign ml-2",
      },
    ],
  },
  {
    name: "sidebar.contents",
    iconClass: "fas fa-file",
    routepath: "/pages",
  },
  {
    name: "sidebar.emailNotifications",
    iconClass: "fas fa-envelope",
    routepath: "/notification",
  },
  /** 21 march 2022 comment Announcement Module skeep as client requirement */
  {
    name: "sidebar.announcements",
    iconClass: "fas fa-bullhorn",
    routepath: "/announcements",
  },
];

// Comments:::::::

//  If you want one level child then look below example

/*
  {
    name: 'sidebar.forms',
    iconClass: 'fab fa-wpforms',
    child: [
      {
        listname: 'sidebar.regularforms',
        routepath: '/regularform',
        shortname: 'RF'
      }
    ]
  }
*/

//  If you want Second level child then look below example

/*
   {
      name: 'sidebar.pages',
      iconClass: 'fas fa-print',
      child: [
        {
          listname: 'sidebar.authentication',
          iconClass: 'fas fa-user',
          child: [
            {
              listname: 'sidebar.login',
              routepath: '/login',
              shortname: 'L'
            },
          ]
        }
      ]
    }
*/
