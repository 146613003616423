import Editprofile from "../views/Editprofile/Editprofile";
import ChangePassword from "../views/ChangePassword/ChangePassword";
// import StaticPage from "../views/StaticPageManagement/UserPage";
// import StaticPageEdit from "../views/StaticPageManagement/UserPageEdit";
// import StaticPageView from "../views/StaticPageManagement/StaticPageView";
import Dashboard from "../views/Dashboard/Dashboard";
import AdminsPageView from "../views/Admins/AdminsPageView";
import SubAdminsPageView from "../views/Admins/SubAdminsPageView";
import AdminEdit from "../views/Admins/AdminEdit";
import SubAdminEdit from "../views/Admins/SubAdminEdit";
import RoleView from "../views/Roles/RoleView";
import RoleEdit from "../views/Roles/RoleEdit";
import UserPageView from "../views/UserPageManagement/UserPageView";
import UserPageEdit from "../views/UserPageManagement/UserPageEdit";
import SubscriptionView from "../views/Subscription/SubscriptionView";
import TransactionView from "../views/PaymentTransaction/TransactionView";
import SubscriptionEdit from "../views/Subscription/SubscriptionEdit";
import AISubscriptionView from "../views/AISubscription/AISubcritionView"; // added last
import AISubscriptionEdit from "../views/AISubscription/AISubcritionEdit"; // added last
import PageView from "../views/StaticPage/PageView";
import PageEdit from "../views/StaticPage/PageEdit";
import NotificationView from "../views/EmailNotification/NotificationView";
import NotificationAdd from "../views/EmailNotification/NotificationAdd";
/** 21 march 2022 comment Announcement Module skeep as client requirement */
import AnnouncementView from "../views/Announcements/AnnouncementView";
import AnnouncementAdd from "../views/Announcements/AnnouncementsAdd";
import CommentsView from "../views/Comments/CommentsView";
import CommentSettingsView from "../views/Comments/CommentSettingsView";
import CommentSettingsEdit from "../views/Comments/CommentSettingsEdit";

const dashboardRoutes = [
  { path: "/dashboard", component: Dashboard },

  { path: "/admin", component: AdminsPageView },
  { path: "/sub-admin", component: SubAdminsPageView },
  { path: "/admin/add", component: AdminEdit },
  { path: "/sub-admin/edit/:id", component: SubAdminEdit },
  { path: "/roles", component: RoleView },
  { path: "/roles/add", component: RoleEdit },
  { path: "/roles/edit/:id", component: RoleEdit },

  { path: "/editprofile", component: Editprofile },
  { path: "/changePassword", component: ChangePassword },

  { path: "/user-management", component: UserPageView },
  { path: "/user-management/edit/:id", component: UserPageEdit },

  { path: "/subscription", component: SubscriptionView },
  // { path: "/subscription/add", component: SubscriptionEdit },
  { path: "/subscription/edit/:id", component: SubscriptionEdit },
  { path: "/payment-transaction", component: TransactionView },

  { path: "/AI-subscription", component: AISubscriptionView }, // added last
  { path: "/AI-subscription/add", component: AISubscriptionEdit },
  { path: "/AI-subscription/edit/:id", component: AISubscriptionEdit }, // added last

  { path: "/pages", component: PageView },
  { path: "/pages/add", component: PageEdit },
  { path: "/pages/edit/:id", component: PageEdit },

  { path: "/notification", component: NotificationView },
  { path: "/notification/add", component: NotificationAdd },

  /** 21 march 2022 comment Announcement Module skeep as client requirement */
  { path: "/announcements", component: AnnouncementView },
  { path: "/announcements/add", component: AnnouncementAdd },

  /* 16 May Comment Module */
  { path: "/comments", component: CommentsView },
  { path: "/commentSettings", component: CommentSettingsView },
  { path: "/commentSettings/edit/:id", component: CommentSettingsEdit },
];

export default dashboardRoutes;
